import React from 'react';

const PaginationOne = ({ pages, currentPage, setCurrentPage }) => {
    return (
        <nav>
            <ul className="edu-pagination">
                <li onClick={() => setCurrentPage(currentPage - 1 > 0 ? currentPage - 1 : 0)}><button><i className="ri-arrow-drop-left-line"></i></button></li>
                {
                    Array(pages).fill('').map((_, index) => <li onClick={() => setCurrentPage(index)} className={currentPage == index && 'active'}><button>{index+1}</button></li>)
                }
                <li onClick={() => setCurrentPage(currentPage + 1 < pages ? currentPage + 1 : pages - 1)}><button><i className="ri-arrow-drop-right-line"></i></button></li>
            </ul>
        </nav>
    )                    
}

export default PaginationOne;