import React from 'react';
import { Link } from 'react-router-dom';
import { slugify } from '../../utils';
import { SERVER_URL } from '../../utils/fetch';

const CourseTwo = ({ data, classes }) => {

    return (
        <div className={`edu-card card-type-2 radius-small ${ classes ? classes : '' }`}>
            <div className="inner">
                <div className="thumbnail">
                    <Link to={process.env.PUBLIC_URL + `/course/${slugify(data.title)}`}>
                        <img className="w-100" src={`${process.env.PUBLIC_URL}/images/course/course-01/${data.image}`} alt="Course Thumb" />
                    </Link>
                    <div className="top-position status-group left-top">
                        <span className="eduvibe-status status-02"><i className="icon-time-line"></i> {data.duration}</span>
                    </div>
                    <div className="wishlist-top-right">
                        <button className="wishlist-btn"><i className="icon-Heart"></i></button>
                    </div>
                </div>
                <div className="content">
                    <div className="card-top">
                       
                        <div className="edu-rating rating-default">
                            <div className="rating eduvibe-course-rating-stars">
                                {
                                    Array(5).fill("").map((_, index) => {
                                        if (index + 1 <= data.rating) {
                                            return <i className="icon-Star" />
                                        } else {
                                            return <i key={`${index}-23`} className="off icon-Star" />
                                        }
                                    })    
                                }
                            </div>
                            <span className="rating-count">({data.rating})</span>
                        </div>
                    </div>
                    <h6 className="title">
                        <Link to={process.env.PUBLIC_URL + `/course/${slugify(data.title)}`}>{data.title}</Link>
                    </h6>
                    <ul className="edu-meta meta-01">
                        <li><i className="icon-group-line"></i>{data.acronym}</li>
                        {/* <li><i className="icon-file-list-4-line"></i>{data.lesson} Lessons</li> */}
                    </ul>
                    <div className="card-bottom">
                        <div className="price-list price-style-01">
                            {
                                data.price === '0' ?
                                    <div className="price current-price">Free</div>
                                :
                                    <div className="price current-price">${data.price} USD</div>
                            }
                            { 
                                data.oldPrice && <div className="price old-price">${data.oldPrice} USD</div> 
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default CourseTwo;