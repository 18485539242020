import React, { useState, useContext, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Accordion } from 'react-bootstrap';
import { useAccordionButton } from 'react-bootstrap/AccordionButton';
import AccordionContext from 'react-bootstrap/AccordionContext';
import { slugify } from '../../utils';
import SEO from '../../common/SEO';
import Layout from '../../common/Layout';
import BreadcrumbOne from '../../common/breadcrumb/BreadcrumbOne';
import { SERVER_URL } from "../../utils/fetch";
import JSZip from 'jszip';
import jsPDF from 'jspdf';
import {  Document, Page, Text, View, pdf, Link, StyleSheet, Image } from "@react-pdf/renderer";

const CustomToggle = ({ children, eventKey }) => {
    const { activeEventKey } = useContext(AccordionContext);
    const decoratedOnClick = useAccordionButton(eventKey);
    const isCurrentEventKey = activeEventKey === eventKey;
    return <button type="button" onClick={decoratedOnClick} aria-expanded={isCurrentEventKey ? true : false} className="edu-accordion-button">{children}</button>
}

const CurriculumContent = ({ tasks, generateTaskComponent }) => {
    const [activeId, setActiveId] = useState('0');

    function toggleActive(id) {
        if (activeId === id) {
            setActiveId(null);
        } else {
            setActiveId(id);
        }
    }

    const handleCopy = (task) => {

        const copyComponent = generateTaskComponent(task);
        document.body.appendChild(copyComponent);
        const range = document.createRange();
        range.selectNode(copyComponent);
        const selection = window.getSelection();
        selection.removeAllRanges();
        selection.addRange(range);
        const successful = document.execCommand('copy');
        // navigator.clipboard.writeText(copyString);
        copyComponent.style.display = 'none';

    }

    return (
        <Accordion bsPrefix="edu-accordion-02" defaultActiveKey={activeId} flush>
            {
                <Accordion.Item eventKey={'0'} key={0}>
                    <div className="edu-accordion-header">
                        <CustomToggle eventKey={'0'}>Tasks</CustomToggle>
                    </div>
                    <Accordion.Body bsPrefix="edu-accordion-body">
                        <ul>
                            {
                                tasks?.map((task, index) => (
                                    <li key={index}>
                                        <div className="text"><i className="icon-draft-line"></i>{task.name}</div>
                                        <div className="icon"><button onClick={() =>handleCopy(task)}>Copy</button></div>
                                    </li>
                                ))

                            }
                        </ul>
                    </Accordion.Body>
                </Accordion.Item>
            }
        </Accordion>
    )
}

const DownloadDetail = () => {
    const { name } = useParams();
    const [CourseData, setCourseData] = useState([]);
    const [currentCourse, setCurrentCourse] = useState({});
    useEffect(() => {
        const getCourses = async () => {
            const response = await fetch(`${SERVER_URL}/api/course`).then(res => res.json())
            if (response.success) {
                let courses = response.data;
                courses = courses.map((course, index) => {
                    let total_rating = 0;
                    const ratings = course?.rating ? course?.rating : [];
                    if (ratings.length > 0) {
                        ratings.map(rt => {
                            total_rating += rt.mark;
                        })
                        total_rating = parseInt(total_rating / ratings.length);
                    }
                    const title = course.name.length > 40 ? `${course.name.slice(0, 40)} ...` : course.name;
                    return {
                        "id": course._id,
                        "title": title,
                        'origin_name': course?.file?.origin_name,
                        'img': `${SERVER_URL}/public/${course?.file?.name}`,
                        "instructor": "James Carlson",
                        "duration": `${course.duration} Weeks`,
                        "level": course.level.toUpperCase(),
                        "rating": total_rating ? total_rating : 0,
                        "acronym": course?.university?.acronym,
                        "price": course.current_price,
                        "oldPrice": course.original_price,
                        "review": ratings.length ? ratings.length : 0,
                        "details": course.description,
                        "tasks": course.tasks,
                        'university': course.university

                    }
                }
                )
                const temp_current_course = courses.find(item => slugify(item.title) == name);
                setCourseData(courses);
                setCurrentCourse(temp_current_course)
            }
        }
        getCourses();
    }, [])

    const generateCourseComponent = () => {
        const university = currentCourse?.university?.name || "";
        const tasks = currentCourse?.tasks || [];
        const universityName = university;
        const universityHref = university;
        const { title } = currentCourse;
        let taskStr = '';
        let tasksComponent = document.createElement("div");
        for (let i = 0; i < tasks.length; i++) {
            const taskName = tasks[i].name;
            const taskDes = tasks[i].description
            const taskComponent = document.createElement("div");
            const taskHrefComponent = document.createElement("a");
            taskHrefComponent.href = `https://maintutors.com/course/${slugify(title)}/task/${slugify(taskName)}`;
            taskHrefComponent.innerText = taskName;
            // Get raw text from html
            const tempComponent = document.createElement('span');
            tempComponent.innerHTML = taskDes;

            let rawText = tempComponent.innerText;
            let flag = rawText.length > 500;
            rawText = rawText.length > 500 ? rawText.slice(0,500) + "...." : rawText;

            const readmoreComponent = document.createElement('a');
            readmoreComponent.href = `https://maintutors.com/course/${slugify(title)}/task/${slugify(taskName)}`;
            readmoreComponent.innerText = 'read more';  

            const taskDesWrapComponent = document.createElement('div');
            const taskDesComponent = document.createElement('span');
            taskDesComponent.innerText = rawText != 'undefined' ? rawText : ''
            taskComponent.appendChild(taskHrefComponent);
            
            if (flag) {
                taskDesComponent.appendChild(readmoreComponent)
            }
            taskDesWrapComponent.appendChild(taskDesComponent);
            taskComponent.appendChild(taskDesWrapComponent);
            tasksComponent.appendChild(taskComponent);
        }
        const copyComponent = document.createElement("div");
        // copyComponent.style.display = 'none';

        const universityWrapComponent = document.createElement('div');

        const universityHrefComponent = document.createElement('a');
        universityHrefComponent.href = `https://maintutors.com/university/${slugify(universityHref)}`
        universityHrefComponent.innerText = universityName;


        const universityLabelComponent = document.createElement("span");
        universityLabelComponent.innerText = `UNIVERSITY: `;

        universityWrapComponent.appendChild(universityLabelComponent);
        universityWrapComponent.appendChild(universityHrefComponent);

        const courseWrapComponent = document.createElement('div');

        const courseHrefComponent = document.createElement('a');
        
        courseHrefComponent.href = `https://maintutors.com/course/${slugify(title)}`
        courseHrefComponent.innerText = title;

        const courseLabelComponent = document.createElement('span');
        courseLabelComponent.innerText = 'COURSE: ';

        courseWrapComponent.appendChild(courseLabelComponent);
        courseWrapComponent.appendChild(courseHrefComponent);


        copyComponent.appendChild(universityWrapComponent);
        copyComponent.appendChild(courseWrapComponent);
        copyComponent.appendChild(tasksComponent);

        return copyComponent;
    }

    const generateTaskComponent = (task) => {
        const { title } = currentCourse;
        const university = currentCourse.university;
        const universityName = university.name;
        const universityHref = university;


        const universityWrapComponent = document.createElement('div');

        const universityHrefComponent = document.createElement('a');
        universityHrefComponent.href = `https://maintutors.com/university/${slugify(universityHref)}`
        universityHrefComponent.innerText = universityName;


        const universityLabelComponent = document.createElement("span");
        universityLabelComponent.innerText = `UNIVERSITY: `;

        universityWrapComponent.appendChild(universityLabelComponent);
        universityWrapComponent.appendChild(universityHrefComponent);

        const courseName = title;

        const courseWrapComponent = document.createElement('div');

        const courseHrefComponent = document.createElement('a');
        courseHrefComponent.href = `https://maintutors.com/course/${slugify(courseName)}`
        courseHrefComponent.innerText = courseName;

        const courseLabelComponent = document.createElement('span');
        courseLabelComponent.innerText = 'COURSE: ';

        courseWrapComponent.appendChild(courseLabelComponent);
        courseWrapComponent.appendChild(courseHrefComponent);

        const taskName = task.name;
        const taskDes = task.description;


        const taskComponent = document.createElement("div");
        const taskHrefComponent = document.createElement("a");
        taskHrefComponent.href = `https://maintutors.com/course/${slugify(courseName)}/task/${slugify(taskName)}`;
        taskHrefComponent.innerText = taskName;
        // Get raw text from html
        const taskDesComponent = document.createElement('div');
        taskDesComponent.innerHTML = taskDes;
        
        const taskLabelComponent = document.createElement('span');
        taskLabelComponent.innerText = 'ASSIGNMENT: ';

        taskComponent.appendChild(taskLabelComponent);
        taskComponent.appendChild(taskHrefComponent);
        taskComponent.appendChild(taskDesComponent);

        const copyComponent = document.createElement("div");

        copyComponent.appendChild(universityWrapComponent);
        copyComponent.appendChild(courseWrapComponent);
        copyComponent.appendChild(taskComponent);
        return copyComponent;
    }

    const handleCopy = () => {
        const copyComponent = generateCourseComponent();
        document.body.appendChild(copyComponent);
        const range = document.createRange();
        range.selectNode(copyComponent);
        const selection = window.getSelection();
        selection.removeAllRanges();
        selection.addRange(range);
        const successful = document.execCommand('copy');
        // navigator.clipboard.writeText(copyString);
        copyComponent.style.display = 'none';
    }

    const handleImage = async () => {
        const { img, origin_name } = currentCourse;
        await downloadFile(img, origin_name);
    }


    const downloadFile = async (url, name) => {
        const blobObj = await fetch(url).then(res => res.blob());
        const blob = window.URL.createObjectURL(blobObj);
        const anchor = document.createElement('a');
        anchor.style.display = 'none';
        anchor.href = blob;
        anchor.download = name;
        document.body.appendChild(anchor);
        anchor.click();
        window.URL.revokeObjectURL(blob);
    }

    const handleFiles = async () => {
        const tasks = currentCourse.tasks;
        const title = currentCourse.title;
        let urls = [];
        let names = [];
        for (let i = 0; i < tasks.length; i++) {
            const task = tasks[i];
            const files = task.files;
            for (let j = 0; j < files.length; j++) {
                const file = files[j];
                const url = `${SERVER_URL}/public/${file.name}`;
                const name = file.origin_name;
                // await downloadFile(url, name)
                urls.push(url);
                names.push(name)
            }
        }

        const fileName = `${title}.zip`;
        saveZip(fileName, urls, names);
    }

    const saveZip = (filename, urls, names) => {
        if(!urls || urls.length == 0) return;
    
        const zip = new JSZip();
        const folder = zip.folder("files"); // folder name where all files will be placed in 
    
        urls.forEach((url, index) => {
            const blobPromise = fetch(url).then((r) => {
                if (r.status === 200) return r.blob();
                return Promise.reject(new Error(r.statusText));
            });
            const name = names[index];
            folder.file(name, blobPromise);
        });
    
        zip.generateAsync({ type: "blob" }).then((blob) => {
            const blobObj = window.URL.createObjectURL(blob);
            const anchor = document.createElement('a');
            anchor.style.display = 'none';
            anchor.href = blobObj;
            anchor.download = filename;
            document.body.appendChild(anchor);
            anchor.click();
            window.URL.revokeObjectURL(blobObj);
        });
    
    };

    const styles = StyleSheet.create({
        page: {
          flexDirection: 'row',
        },
        section: {
          margin: 10,
          padding: 10,
          flexGrow: 1
        }
    });

    const handleIssue = async () => {
        const university = currentCourse.university;
        const tasks = currentCourse.tasks;
        const title = currentCourse.title;
        // let courseDoc = new jsPDF();
        let courseName = `${title}.pdf`;

        const universityName = university.name;
        const universityHref = university.acronym;
        const zip = new JSZip();
        const folder = zip.folder("files");

        let descriptions = [];
        for (let i = 0; i < tasks.length; i++) {
            const taskName = tasks[i].name;
            const taskDes = tasks[i].description
            // Get raw text from html
            const tempComponent = document.createElement('span');
            tempComponent.innerHTML = taskDes;

            let rawText = tempComponent.innerText;
            let flag = rawText.length > 500;
            rawText = rawText.length > 500 ? rawText.slice(0,500) + "...." : rawText;

            const readmoreComponent = document.createElement('a');
            readmoreComponent.href = `https://gradefoster.com/course?course=${slugify(title)}&task=${slugify(taskName)}`;
            readmoreComponent.innerText = 'read more';  
            descriptions.push(rawText)
        }

        const courseDox = <Document>
                                <Page size='B5' style={styles.page}>
                                    <View style={styles.section}>
                                        <Text>UNIVERSITY: <Link href={`https://gradefoster.com/university?university=${slugify(universityHref)}`}> {universityName }</Link></Text>
                                        <Text>COURSE: <Link href={`https://gradefoster.com/course?course=${slugify(title)}`}>{ title }</Link></Text>
                                        {
                                            tasks.map((task, index) => <>
                                                <Text><Link href={`https://gradefoster.com/course?course=${slugify(title)}&task=${slugify(task.name)}`}>{task.name}</Link></Text>
                                                <Text>{descriptions[index]} <Link href={`https://gradefoster.com/course?course=${slugify(title)}&task=${slugify(task.name)}`}>...read more</Link></Text>
                                            </>)
                                        }
                                    </View>
                                </Page>
                            </Document>;
        
        // const blobPdf = <Document>;
        const blobPdf = await pdf(courseDox);
        blobPdf.updateContainer(courseDox);
        const result = await blobPdf.toBlob();
        folder.file(courseName, result);

        for (let i = 0; i < tasks.length; i++) {
            const task = tasks[i];
            const name = `${task.name}.pdf`;
            const taskDox = <Document>
                <Page size='B5' style={styles.page}>
                    <View style={styles.section}>
                        <Text>UNIVERSITY: <Link href={`https://maintutors.com/university?university=${slugify(universityHref)}`}> {universityName }</Link></Text>
                        <Text>COURSE: <Link href={`https://maintutors.com/course/${slugify(title)}`}>{ title }</Link></Text>
                        <Text>ASSIGNMENT: <Link href={`https://maintutors.com/course?course=${slugify(title)}&task=${slugify(task.name)}`}>{task.name}</Link></Text>           
                        <Image src='/task.png'/>
                        <Text marginTop='10px'>{descriptions[i]}</Text>
                    </View>
                </Page>
            </Document>

            const taskBlobPdf = await pdf(taskDox);
            taskBlobPdf.updateContainer(taskDox);
            const result = await taskBlobPdf.toBlob();
            folder.file(name, result);

        }
        
        zip.generateAsync({ type: "blob" }).then((blob) => {
            const blobObj = window.URL.createObjectURL(blob);
            const anchor = document.createElement('a');
            anchor.style.display = 'none';
            anchor.href = blobObj;
            anchor.download = `${title}.zip`;
            document.body.appendChild(anchor);
            anchor.click();
            window.URL.revokeObjectURL(blobObj);
        });
        

    }

    return (
        <>
            <SEO title={currentCourse?.title} />
            <Layout>
                <BreadcrumbOne
                    title={currentCourse?.university?.name}
                    rootUrl="/"
                    parentUrl="Home"
                    currentUrl="Course"
                />
                <div className="edu-course-details-area edu-section-gap bg-color-white">
                    <div className="container">

                        <div className="row g-5">
                            <div className="col-xl-12 col-lg-12">
                                <div className="course-details-content">

                                    <h3 className="title">{currentCourse?.title}</h3>

                                    <div className="course-details-card mt--40">
                                        <div className="course-details-two-content">
                                            <CurriculumContent 
                                                tasks={currentCourse?.tasks} 
                                                generateTaskComponent={generateTaskComponent}/>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                        <div className='row g-5'>
                            <div className='col-xs-3 col-lg-3'>
                                <div className="read-more-btn mt--15">
                                    <button className="edu-btn w-100 text-center" onClick={handleCopy}>Copy ClipBoard</button>
                                </div>
                            </div>

                            <div className='col-xs-3 col-lg-3'>
                                <div className="read-more-btn mt--15">
                                    <button className="edu-btn w-100 text-center" onClick={handleImage}>Download Image</button>
                                </div>
                            </div>

                            <div className='col-xs-3 col-lg-3'>
                                <div className="read-more-btn mt--15">
                                    <button className="edu-btn w-100 text-center" onClick={handleFiles}>Download Files</button>
                                </div>
                            </div>

                            <div className='col-xs-3 col-lg-3'>
                                <div className="read-more-btn mt--15">
                                    <button className="edu-btn w-100 text-center" onClick={handleIssue}>Download Issue</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Layout>
        </>
    )
}
export default DownloadDetail;