import React from 'react';
import Slider from 'react-slick';
import { TestimonialFourParams } from '../../utils/script';

const data = [
    {
        name: "John James",
        details: "I was struggling with my math class and was at risk of failing. I turned to Maintutors.com for help and they were able to provide me with a tutor who helped me understand the concepts and improve my grades. Thanks to Maintutors.com, I passed my math class with flying colors!",
        designation: "Student",
        image: "client-01.png",
        rating: 5
    },
    {
        name: "Sarah White",
        details: "I was worried about my son's progress in his language arts class. We turned to Maintutors.com for help and they were able to provide us with a tutor who was patient, knowledgeable, and engaging. My son's grades improved dramatically, and he now enjoys his language arts class!",
        designation: "Parent",
        image: "client-02.png",
        rating: 5
    },
    {
        name: "Mark Walter",
        details: "I needed help with a specific project for my history class. Maintutors.com provided me with a custom service that was tailored to my needs. The tutor was able to guide me through the project and provide me with valuable feedback that helped me improve my work. I'm grateful for the help I received from Maintutors.com!",
        designation: "High School Student",
        image: "client-03.png",
        rating: 5
    },
    {
        name: "Jane Nicholas",
        details: "I was working full-time and was unable to attend my online classes. Maintutors.com provided me with a tutor who was able to attend my classes for me and take notes. They were also able to complete assignments and submit them on my behalf. Thanks to Maintutors.com, I was able to complete my degree on time and without any issues!",
        designation: "College Student",
        image: "client-04.png",
        rating: 5
    }
];

const TestimonialFour = ( props ) => {
    const classes = `eduvibe-testimonial-four edu-testimonial-activation testimonial-item-3 mt--40 edu-slick-button ${props.className ? props.className : '' }`;
    return (
        <Slider
            className={classes}
            { ...TestimonialFourParams }
        >
            { data.map( ( item, i ) => (
                <div className="testimonial-card-box" key={ i }>
                    <div className="inner">
                        <div className="client-info">
                            <div className="thumbnail">
                                <img src={`${process.env.PUBLIC_URL}/images/testimonial/testimonial-04/${item.image}`} alt="Client Thumb" />
                            </div>
                            <div className="content">
                                <h6 className="title">{item.name}</h6>
                                <span className="designation">{item.designation}</span>
                            </div>
                        </div>
                        <p className="description">“ {item.details} ”</p>
                        <div className="rating eduvibe-course-rating-stars">
                            {[...Array(5)].map((_, index) => {
                                return <i key={index} className={ index < item.rating ? 'on icon-Star' : 'off icon-Star' }></i>
                            })}
                        </div>
                    </div>
                </div>
            ) ) }
        </Slider>
    )
}

export default TestimonialFour;