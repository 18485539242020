import React, { useEffect, useState } from 'react';
import Slider from 'react-slick';
import SEO from '../../common/SEO';
import Layout from '../../common/Layout';
import BreadcrumbOne from '../../common/breadcrumb/BreadcrumbOne';
import CourseTypeOne from '../../components/course/CourseTypeOne';
import PaginationOne from "../../components/pagination/PaginationOne";
import { ThreeColumnCarousel } from '../../utils/script';
import { SERVER_URL } from "../../utils/fetch";
import { slugify } from '../../utils'

const University = () => {

    const [CourseData, setCourseData] = useState([]);

    const [currentPage, setCurrentPage] = useState(0);
    const pagenates = ['A','B','C','D','E','F','G','H','I','J','K','L','M','N','O','P','Q','R','S','T','U','V','W','X','Y','Z'];

    useEffect(() => {
        const getUniversity = async () => {
            const response = await fetch(`${SERVER_URL}/api/university`).then(res => res.json())
            if (response.success) {
                let univeristy = response.data.sort((a,b) => a.name > b.name ? 1 : -1);
                univeristy = univeristy.map((item, index) => ({
                    title: item.name,
                    country: item.country,
                    state: item.state,
                    city: item.city,
                    numberOfCourses: item.courses.length,
                    icon: 'icon-Campus',
                    link: `/university/${slugify(item.name)}`,
                    description: item.description
                }))
                setCourseData(univeristy)
            }
        }
        getUniversity();
    }, [])


    return (
        <>
            <SEO title="University" />
            <Layout>
                <BreadcrumbOne 
                    title="University"
                    rootUrl="/"
                    parentUrl="Home"
                    currentUrl="University"
                />
                <div className="edu-elements-area edu-section-gap bg-color-white">
                    <div className="container">
                        <div className="row g-5">
                            <div className="col-lg-12">
                                <div className="section-title text-center">
                                    <span className="pre-title">Our University</span>
                                </div>
                                <div className='row section-body'>
                                    { 
                                        CourseData.filter(item => item.title[0].toLowerCase() == pagenates[currentPage].toLowerCase() ).map((item) => (
                                            <div className="col-md-4 col-lg-4 single-slick-card" style={{ marginTop: 10 }} key={ item.id }>
                                                <CourseTypeOne data={item} />
                                            </div>
                                        ) )
                                    }
                                </div>
                            </div>
                            <div className='col-lg-12'>
                                <nav>
                                    <ul className="edu-pagination">
                                        
                                        {
                                           pagenates.map((page, index) => <li onClick={() => setCurrentPage(index)} className={currentPage == index && 'active'}><button>{page}</button></li>)
                                        }
                                        </ul>
                                </nav>
                            </div>
                        </div>
                    </div>
                </div>
            </Layout>
        </>
    )
}

export default University;