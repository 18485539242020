import { useEffect, useState, useRef } from "react";
import { Modal, Form, Button, Row, Col, Toast, ToastContainer } from 'react-bootstrap';
import { MdStarRate } from 'react-icons/md';
import emailjs from '@emailjs/browser';

const types = [
    'Entire Course-plagiarism free',
    'Single assignment-plagiarism free',
    'Discussion- plagiarism free',
    'Exam',
    'Test-Proctored'
]

const academis_levels = [
    { key: 'high_school', label: 'Hight School', cost: 1 },
    { key: 'college', label: 'College', cost: 3 },
    { key: 'undergraduate', label: 'Undergraduate', cost: 5 },
    { key: 'masters', label: 'Masters', cost: 7 },
    { key: 'phd', label: 'PhD', cost: 10 }
];

const paper_formats = [
    { key: 'apa', label: 'APA' },
    { key: 'mla', label: 'MLA' },
    { key: 'chicago', label: 'CHICAGO' },
    { key: 'havard', label: 'Havard' },
    { key: 'turabian', label: 'Turabian' },
    { key: 'other', label: 'Other' }
];

const premium_options = [
    { key: 'best_writer', label: 'Best available writer', cost: 3 },
    { key: 'enl_writer', label: 'ENL writer', cost: 3 },
    { key: 'turnitin_report', label: 'Turnitin Report', cost: 5 },
    { key: 'grammar_report', label: 'Grammar Report', cost: 3 },
    { key: 'quality_assurance', label: 'Quality Assurance Report', cost: 10 },
    { key: 'spanish_writer', label: 'Spanish Writer', cost: 5 },
    { key: 'chinese_writer', label: 'Chinese Writer', cost: 5 },
    { key: 'human', label: 'Human-Generated Content Report', cost: 5 }
]

const deadLines = [
    { key: '6h', label: '6hrs', cost: 15 },
    { key: '8h', label: '8hrs', cost: 12 },
    { key: '10h', label: '10hrs', cost: 12 },
    { key: '12h', label: '12hrs', cost: 10 },
    { key: '14h', label: '14hrs', cost: 6 },
    { key: '16h', label: '16hrs', cost: 6 },
    { key: '18h', label: '18hrs', cost: 4 },
    { key: '20h', label: '20hrs', cost: 4 },
    { key: '22h', label: '22hrs', cost: 3 },
    { key: '24h', label: '24hrs', cost: 3 },
    { key: '2d', label: '2 days', cost: 2 },
    { key: '3d', label: '3 days', cost: 1},
    { key: '4d', label: '4 days', cost: 0 } 
]

const OrderPopup = ({ isSearchOpen, setIsSearchOpen }) => {
    const form = useRef();
    const [submitState, setSubmitState] = useState(false);
    const [submitContent, setSubmitContent] = useState("");
    const [totalPrice, setTotalPrice] = useState({ academic_level: 0, deadline: 0, numberOfwords: 0, premium_option: 0 });
    const [formData, setFormData] = useState({
        name: 'Hill',
        email: '',
        phone: '',
        course: '',
        budget: 10,
        message: '',
        academic_level: '',
        numberOfwords: 0,
        paper_format: '',
        premium_option: '',
        deadline: 0,
        deadline_type: 'hour'
    });
    const handleSubmit = async (e) => {
        if (formData.name == '' || formData.email == '' || formData.phone == '' || formData.course == '' || formData.budget == undefined || formData.message == '') {
            setSubmitState(true)
            setSubmitContent("Please Input All Fields Correctly!");
            return;
        }
        emailjs
            .send("service_guif2yo", "template_qhk16ks", formData, 'HZZQeRnQ9xmTdK5t2')
            .then(
                (result) => {
                    setSubmitContent("Successfully Sent!");
                    setSubmitState(true)
                    setIsSearchOpen(false)
                },
                (error) => {
                    setSubmitContent("Failed!");
                    setSubmitState(true)
                    setIsSearchOpen(false)
                }
            );
    }

    const handleChange = (name, value) => {
        let price = 0;
        switch (name) {
            case 'academic_level':
                const selectedAcademic = academis_levels.find(level => level.key == value);
                price = selectedAcademic.cost;
                break;

            case 'deadline':
                const selectedDeadline = deadLines.find(item => item.key == value);
                price = selectedDeadline.cost;
                break;
            case 'numberOfwords':
                price = (value - 250) / 250 * 15 + 10;
                break;
            case 'premium_option':
                const selectedPremium = premium_options.find(option => option.key == value);
                price = selectedPremium.cost;
                break;

            default:
                break;
        }

        setTotalPrice({ ...totalPrice, [name]: price })

        setFormData({ ...formData, [name]: value })
    }

    console.log(totalPrice, ">>>> 110")
    return (
        <>
            <Modal
                show={isSearchOpen}
                onHide={() => setIsSearchOpen(false)}
                keyboard={false}
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Body style={{ padding: 0 }}>
                    <Row>
                        <Col md={12} style={{ padding: 30 }}>
                            <Form ref={form} validated>
                                <Form.Group className="mb-3" controlId="name">
                                    <Form.Label>Name of the client</Form.Label>
                                    <Form.Control type="text" placeholder="Hill" style={{ border: '1px solid black', height: 50 }} onChange={(e) => setFormData({ ...formData, name: e.target.value })} />
                                </Form.Group>

                                <Form.Group className="mb-3" controlId="email">
                                    <Form.Label>Email address</Form.Label>
                                    <Form.Control type="email" placeholder="example@domain.io" style={{ border: '1px solid black', height: 50 }} onChange={(e) => setFormData({ ...formData, email: e.target.value })} />
                                </Form.Group>

                                <Form.Group className="mb-3" controlId="course">
                                    <Form.Label>Course</Form.Label>
                                    <Form.Select style={{ fontSize: '1.5rem' }} onChange={(e) => setFormData({ ...formData, course: e.target.value })}>
                                        {
                                            types.map((type, index) => <option key={index} value={type}>{type}</option>)
                                        }
                                    </Form.Select>
                                </Form.Group>

                                <Form.Group className="mb-3" controlId="name">
                                    <Form.Label>Topic</Form.Label>
                                    <Form.Control type="text" placeholder="Hill" style={{ border: '1px solid black', height: 50 }} onChange={(e) => setFormData({ ...formData, name: e.target.value })} />
                                </Form.Group>

                                <Form.Group className="mb-3" controlId="message">
                                    <Form.Label>Instructions</Form.Label>
                                    <Form.Control size='lg' as='textarea' rows={3} placeholder="Message" style={{ border: '1px solid black' }} onChange={(e) => setFormData({ ...formData, message: e.target.value })} />
                                </Form.Group>

                                <Form.Group className="mb-3" controlId="name">
                                    <Form.Label>Attach files</Form.Label>
                                    <Form.Control type="file" placeholder="Hill" style={{ border: '1px solid black' }} onChange={(e) => setFormData({ ...formData, name: e.target.value })} />
                                </Form.Group>

                                <Form.Group className="mb-3" controlId="course">
                                    <Form.Label>Academic Level</Form.Label>
                                    <Form.Select style={{ fontSize: '1.5rem' }} onChange={(e) => handleChange('academic_level', e.target.value)} value={formData.academic_level}>
                                        {
                                            academis_levels.map((level, index) => <option key={index} value={level.key}>{level.label}</option>)
                                        }
                                    </Form.Select>
                                </Form.Group>

                                <Form.Group className="mb-3" as={Row} controlId="name">
                                    <Col sm='12'>
                                        <Form.Label>Deadline</Form.Label>
                                    </Col>
                                    <Col sm='12'>
                                        <Form.Select style={{ fontSize: '1.5rem' }} onChange={(e) => handleChange('deadline', e.target.value)} value={formData.deadline}>
                                            {
                                                deadLines.map((item, index) => <option key={index} value={item.key}>{item.label}</option>)
                                            }
                                        </Form.Select>
                                    </Col>
                                </Form.Group>

                                <Form.Group className="mb-3" controlId="name">
                                    <Form.Label>Number of words(One Page)</Form.Label>
                                    <Form.Control min={250} type="number" placeholder="250" style={{ border: '1px solid black', height: 50 }} onChange={(e) => handleChange('numberOfwords', e.target.value)} />
                                </Form.Group>

                                <Form.Group className="mb-3" controlId="name">
                                    <Form.Label>Paper format(/One)</Form.Label>
                                    <Form.Select style={{ fontSize: '1.5rem' }} onChange={(e) => setFormData({ ...formData, paper_format: e.target.value })}>
                                        {
                                            paper_formats.map((level, index) => <option key={index} value={level.key}>{level.label}</option>)
                                        }
                                    </Form.Select>
                                </Form.Group>

                                <Form.Group className="mb-3" controlId="name">
                                    <Form.Label>Premium Options</Form.Label>
                                    <Form.Select style={{ fontSize: '1.5rem' }} onChange={(e) => handleChange('premium_option', e.target.value)} value={formData.premium_option}>
                                        {
                                            premium_options.map((level, index) => <option key={index} value={level.key}>{level.label}</option>)
                                        }
                                    </Form.Select>
                                </Form.Group>
                                <Row>
                                    <Col xs={12} style={{ textAlign: 'center' }}>
                                        Total Price: ${ totalPrice.academic_level + totalPrice.deadline + totalPrice.numberOfwords + totalPrice.premium_option }
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xs={12} style={{ textAlign: 'center' }}>
                                        <Button variant="primary" onClick={handleSubmit} size='lg' style={{ textAlign: 'center', width: 200, height: 50 }}>
                                            Get a Quote
                                        </Button>
                                    </Col>
                                </Row>
                            </Form>
                        </Col>
                    </Row>

                </Modal.Body>
                <ToastContainer position="middle-center">
                    <Toast show={submitState} onClose={() => setSubmitState(false)} delay={2000} autohide style={{ zIndex: 99 }}>
                        <Toast.Body style={{ fontSize: 24, color: 'black' }}>
                            <div style={{ display: 'flex', justifyContent: 'start', gap: 20 }}>
                                <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                                    <MdStarRate />
                                </div>
                                <div>{submitContent}</div>
                            </div>

                        </Toast.Body>
                    </Toast>
                </ToastContainer>
            </Modal>


        </>


    )
}

export default OrderPopup;