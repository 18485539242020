import React, { useState, useContext, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { slugify } from '../../utils';
import SEO from '../../common/SEO';
import Layout from '../../common/Layout';
import BreadcrumbOne from '../../common/breadcrumb/BreadcrumbOne';
import { SERVER_URL } from "../../utils/fetch";
import { Worker, Viewer, RenderPageProps } from '@react-pdf-viewer/core';
import { defaultLayoutPlugin } from '@react-pdf-viewer/default-layout';
import QuotePopup from "../../components/order";

const pageLayout = {
    tranformSize: ({ size }) => ({
        height: size.height + 30,
        width: size.width + 30,
        borderRadius: 10
    }),

};

const renderPage = (props, setOpen, price) => {
    return (
        <>

            <div style={{ userSelect: "none" }}>
                {props.textLayer.children}
            </div>
            {props.canvasLayer.children}
            {props.annotationLayer.children}
            {
                <div
                    style={
                        {
                            backdropFilter: "blur(10px)",
                            display: "flex",
                            width: props.width,
                            height: (props.height * 0.7),
                            zIndex: 2,
                            bottom: 0,
                            position: "absolute",
                            bottom: 0
                        }
                    } />
            }
            {
                 <div
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        zIndex:3,
                        position: 'absolute',
                        top: '25%',
                        background: 'white',
                        width: props.width/2,
                        borderRadius: 10,
                        padding: 10,
                        boxShadow: '0 3px 4px #e3e3e3'
                    }}
                >
                    <div style={{ display: 'flex', justifyContent: 'center'}}>
                        <h5>To unlock this document pay</h5>
                    </div>
                    <div style={{ display: 'flex', justifyContent: 'center'}}>
                        <h5>$ {price}</h5>
                    </div>
                </div>
            }
            {
                <div
                    style={{
                        display: 'flex',
                        zIndex:3,
                        position: 'absolute',
                        top: '60%',
                        width: props.width,
                        borderRadius: 20,
                        padding: 20,
                        margin: 20,
                        gap: 100
                    }}
                >
                    <div
                        className='order-form'
                        onClick={() =>setOpen(true)}
                    >
                        <h6>Hire us to take</h6>
                        <h6>Your Entire Course Work</h6>
                        <h6>(plagiarism Free)</h6>
                    </div>
                    <div
                        className='order-form'
                    >
                        <h6>Order Original</h6>
                        <h6>Human generated</h6>
                        <h6>Assignment</h6>
                    </div>
                </div>
            }
        </>
    );
};

const DocumentDetail = () => {
    const { name } = useParams();
    const [currentDocument, setCurrentDocument] = useState({});
    const [isQuote, setIsQuote] = useState(false);
    const [price, setPrice] = useState(0);


    useEffect(() => {
        const getDocument = async () => {
            const response = await fetch(`${SERVER_URL}/api/course/file/${name}`).then(res => res.json());
            if (response.success) {
                const document = response.data;
                const current_price = document.price;
                let temp_price = '';
                switch (current_price) {
                    case "original_full_course":
                        temp_price = 350;
                        break;
                    case 'original_document':
                        temp_price = 35;
                        break;
                    case 'third_party_document':
                        temp_price = 10;
                        break;
                    case 'full_course_third_party':
                        temp_price = 90;
                        break;
                
                    default:
                        break;
                }
                setPrice(temp_price);
                setCurrentDocument(document);
            }
        }
        getDocument();
    }, []);

    console.log(currentDocument, ">>>>> 120")

    return (
        <>
            <SEO title={'Document'} description={currentDocument.meta_description} keywords={currentDocument.tags?.join(",")} />
            <Layout>
                <BreadcrumbOne
                    title={currentDocument.original_name}
                    description={
                        <div className='document-title'>
                            <span className='document-title-section'>{currentDocument?.course?.university?.name}</span>
                            <span className='document-title-section'>{currentDocument?.course?.name}</span>
                            <span className='document-title-section'>{currentDocument?.title}</span>
                            <span className='document-title-section'>{currentDocument?.type}</span>
                        </div>
                    }
                    rootUrl="/"
                    parentUrl="Home"
                    currentUrl="Course"
                />
                <div className="edu-course-details-area edu-section-gap bg-color-white">
                    <div className="container">
                        <div className='row g-5'>
                            <div className='col-xl-12 col-lg-12'>

                            </div>
                        </div>
                        <div className="row g-5">
                            <div className="col-xl-12 col-lg-12">
                                <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.6.172/build/pdf.worker.min.js">
                                    <div
                                        style={{
                                            width: '100%',
                                            marginLeft: 'auto',
                                            marginRight: 'auto',
                                        }}
                                    >
                                        {currentDocument?.name && <Viewer
                                            fileUrl={`${SERVER_URL}/public/${currentDocument?.name}`}
                                            renderPage={(e) => renderPage(e,setIsQuote, price)}
                                            pageLayout={pageLayout}
                                        />
                                        }
                                    </div>
                                </Worker>
                            </div>
                        </div>
                        <div className='row g-5' style={{ marginTop: 10 }}>
                            <div className='col-xl-12 col-lg-12'>
                                <h6>{currentDocument.meta_description}</h6>
                            </div>
                            <div className='col-xl-12 col-lg-12'>
                                <div className='document-title'>
                                    {
                                        currentDocument.tags?.map(item => <span className='document-title-section'>{item}</span>)
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Layout>
            <QuotePopup
                isSearchOpen={isQuote}
                setIsSearchOpen={setIsQuote}
            />
        </>
    )
}

export default DocumentDetail;