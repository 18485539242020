import React from 'react';
import SEO from '../../common/SEO';
import HeaderTwo from '../../common/header/HeaderTwo';
import BannerOne from '../../components/banner/BannerOne';
import HomeOneAbout from '../../components/home-one/HomeOneAbout';
import HomeOneCategory from '../../components/home-one/HomeOneCategory';
// import CategoryThree from "../../components/category/CategoryThree";
import HomeOneCourses from '../../components/home-one/HomeOneCourses';
import TestimonialSectionFour from '../../components/testimonial-section/TestimonialSectionFour';
import Pricing from "../../components/pricing";
import VideoSectionTwo from '../../components/video-section/VideoSectionTwo';
import NewsLettterOne from '../../components/newsletter/NewsLettterOne';
import HomeOneBlog from '../../components/home-one/HomeOneBlog';
import FooterOne from '../../common/footer/FooterOne';

const HomeOne = () => {
    return (
        <>
            <SEO 
                title="Get Study help with Online Class Homework, Essays, Assignments, Exams, and Lab-reports,from verified tutors."
                description='We offer Entire Online Coursework Help with Plagiarism-Free Solutions.Our Pre- Existing answers for past assignments can help your study easier'
            />

            <HeaderTwo styles="header-transparent header-style-2" />

            <BannerOne />
            
            <HomeOneAbout />

            <HomeOneCategory />

            <Pricing/>

            <HomeOneCourses />

            <VideoSectionTwo />

            <NewsLettterOne />

            <HomeOneBlog />     

            <FooterOne />   
        </>
    )
}

export default HomeOne;