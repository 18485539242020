import React, { useState } from 'react';
import SEO from '../../common/SEO';
import Layout from '../../common/Layout';
import BreadcrumbOne from '../../common/breadcrumb/BreadcrumbOne';

const plans = [
    {
        id: 1,
        name: 'Purchase Pre-existing Solution',
        price: '$21.00',
        subtitle: 'Per Single Assignment',
        description: 'This an already done solution that works as a revision guide',
        buttonText: 'Buy This Plan',
        purchaseLink: '#',
        features: [
            'Get your Course Done on your behalf by our Professionals',
            '100% Confidentiality'
        ]
    },
    {
        id: 2,
        name: 'Purchase Single Original Solution',
        price: '$30',
        subtitle: 'Per page per each assignment',
        buttonText: 'Buy This Plan',
        purchaseLink: '#',
        features: [
            'Original Turnitin pass',
            'Ready for submission in your class',
            'Human Regenerated content; not AIs',
            'Early Delivery',
            'Early Delivery'
        ]
    },
    {
        id: 3,
        name: 'Purchase Entire Course Solution',
        price: '$99',
        subtitle: 'Per each course',
        description: 'All listed Solution Files are available for Purchase',
        buttonText: 'Buy This Plan',
        purchaseLink: '#',
        features: [
            'Already Solution available for Download',
            'Vivid Guidelines for each Assignment file',
            'Instant Delivery',
            'Easy to Download',
            '100% Confidentiality'
        ]
    },
    {
        id: 4,
        name: 'Purchase Entire Course Solution',
        active: true,
        badgeText: 'Popular',
        price: '$100',
        subtitle: 'Per week',
        description: '',
        buttonText: 'Buy This Plan',
        purchaseLink: '#',
        features: [
            'Original work guaranteed',
            'You relax and we take all tasks for the class',
            'Submissions are done on your behalf',
            'Plagiarism Free and Turnitin Pass',
            'Human Generated Content',
            'Grade A and Minimum B guaranteed',
            'Money Back Guarantee Blow Grade B',
            'ENL writers to Take your Class',
            '100% Confidentiality'
        ]
    }
]

const Pricing = () => {
    

    return (
        <>
            <SEO title="Pricing Plan" description='Assignments Help Company [35% Off], Free Homework Help for Referrals Coupon'/>
            <Layout>
                <BreadcrumbOne
                    title="Pricing Plan"
                    rootUrl="/"
                    parentUrl="Home"
                    currentUrl="Pricing Plan"
                />
                <div className="edu-pricing-area edu-section-gap bg-image">
                    <div className="container">
                        <div className="row g-5">
                            <div className="col-lg-12">
                                <div className="section-title text-start">
                                    <span className="pre-title">Pricing Plan</span>
                                    <h3 className="title">Choose Your Pricing Plan</h3>
                                </div>
                            </div>
                        </div>
                        <div className="row g-5 mt--20">
                            {
                                plans.map((data, index) => (
                                    <div className="col-lg-3 col-md-6 col-12" key={index}>
                                        <div className={`pricing-table ${data.active === true ? ' active' : ''}`}>
                                            <div className="pricing-header">
                                                {
                                                    data.active === true && <div className="edu-badge"><span>{data.badgeText}</span></div>
                                                }
                                                <h3 className="title">{data.name}</h3>
                                                <p>Starting at:</p>
                                                <div className="price-wrap">
                                                    <div className="yearly-pricing">
                                                        <span className="amount">{data.price}</span>
                                                    </div>
                                                </div>
                                                <p>{data.subtitle}</p>
                                                <p>{data.description}</p>
                                            </div>
                                            {data.features && data.features.length > 0 &&
                                                <div className="pricing-body">
                                                    <ul className="list-item">
                                                        {
                                                            data.features.map((feature, i) => <li key={i}><i className="icon-checkbox-circle-line"></i>{feature}</li>)
                                                        }
                                                    </ul>
                                                </div>
                                            }
                                            <div className="pricing-btn">
                                                <a className={`edu-btn ${data.active !== true ? ' btn-dark' : ''}`} href={data.purchaseLink}>
                                                    {data.buttonText}<i className="icon-arrow-right-line-right"></i>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                ))
                            }
                        </div>
                    </div>
                </div>
            </Layout>
        </>
    )
}

export default Pricing;