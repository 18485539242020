import React, { useState, useContext } from 'react';
import { Accordion } from 'react-bootstrap';
import { useAccordionButton } from 'react-bootstrap/AccordionButton';
import AccordionContext from 'react-bootstrap/AccordionContext';
import SEO from '../../common/SEO';
import Layout from '../../common/Layout';
import BreadcrumbOne from '../../common/breadcrumb/BreadcrumbOne';
 
const faqCategories = [
    {
        id: 1,
        slug: 'products-services',
        label: 'Questions about our products/Services'
    },
    {
        id: 2,
        slug: 'procedures',
        label: 'Questions about our procedures'
    },
    {
        id: 3,
        slug: 'gurantees',
        label: 'Questions About Guarantees'
    }
];

const productSeverices = {
    columns: [
        {
            id: 1,
            title: 'What kind of writing services do you provide?',
            body: 'Learning management system, combines a wide range of features to present a class setting without having the students come into a physical classroom. It all depends on the WordPress plugin you go with, but in general.'
        },
        {
            id: 2,
            title: 'Who is going to take my entire class?',
            body: 'If you are struggling to keep up with your coursework or simply do not have the time to attend every class, we can help. Our team of experienced academic writers can take your entire class for you, including completing assignments, taking quizzes, and even participating in discussion forums. This service is ideal for busy professionals or students who are juggling multiple commitments.'
        },
        {
            id: 3,
            title: 'Who is going to write my paper?',
            body: ' If you have a paper due and are struggling to come up with ideas or complete the assignment on time, our team of experienced writers can help. We offer custom paper writing services for all academic levels, from high school to graduate school. Our writers are experts in their respective fields and can deliver well-researched and well-written papers that meet the requirements of your assignment.'
        },
        {
            id: 4,
            title: 'How does it work?',
            body: 'To get started, simply contact us with your request and provide us with all the necessary information about your class or paper. We will assign a writer to your project who will work with you to ensure that your needs are met. You will have the opportunity to review drafts and make any necessary changes before the final product is delivered.'
        },
        {
            id: 5,
            title: 'Is my information safe on your hands?',
            body: 'At our academic writing service, we take your privacy and security seriously. We use advanced security protocols to protect your information and ensure that it remains confidential. We also never share your information with third parties or use it for any purpose other than completing your project.'
        },
        {
            id: 6,
            title: 'How long it take to create a video course?',
            body: "Creating a video course can be a time-consuming process, but our team of experienced writers and video editors can help. We work with you to create a customized course that meets your needs and reflects your expertise. The length of time it takes to create a video course depends on the complexity of the content and the amount of editing required. However, we always work efficiently to deliver high-quality content within the specified timeframe. Can I directly make submissions of pre-existing solutions to my school? No, we do not condone academic dishonesty or plagiarism in any form. Our services are intended to provide guidance and assistance to students who are struggling with their coursework. We do not provide pre-existing solutions that can be directly submitted to your school."
        },
        {
            id: 7,
            title: 'Can you take the proctored exam for me?',
            body: 'No, we do not offer services that involve taking proctored exams on behalf of our clients. Our academic writing services are designed to help you learn and succeed in your academic career, not to cheat. However, we can provide guidance and assistance with exam preparation and study strategies to help you succeed on your own. Our academic writing services are designed to help students and professionals achieve their academic and career goals. Whether you need help with writing papers, taking classes, creating video courses, or preparing for exams, we have the expertise and experience to help you succeed. Our team of experienced writers and editors are committed to delivering high-quality content that meets your needs and exceeds your expectations.'
        }
    ]
}

const procedures = {
    column: [
        {
            id: 1,
            title: 'How do I place an order and create “MY Account”?',
            body: 'To place an order, simply visit our website and fill out the order form, providing us with all the necessary information about your assignment. If you are a first-time user, you will need to create an account by providing us with your name, email address, and a password. Once your account is created, you can log in at any time to access your order history, communicate with your writer, and manage your account details.'
        },
        {
            id: 2,
            title: 'Can I get any Discount?',
            body: 'Yes, we offer discounts to both new and returning customers. Our discount program is designed to provide you with the best possible value for our services while maintaining the highest level of quality. We offer a variety of discounts, including first-time customer discounts, bulk order discounts, and loyalty discounts.'
        },
        {
            id: 3,
            title: 'How long lond does it take to complete an academic paper?',
            body: 'The length of time it takes to complete an academic paper depends on the complexity of the assignment and the deadline. We work with you to establish a deadline that meets your needs and ensures that the paper is completed on time. We also offer rush services for urgent assignments.'
        },
        {
            id: 4,
            title: 'Who is going to choose an expert to write for me? Can I request a specific writer?',
            body: ' Can I request a specific writer? Our team of experienced writers and editors will carefully review your order and assign the best writer to work on your assignment. We take into account the subject matter, level of difficulty, and writer expertise when making this decision. While you cannot request a specific writer, you can communicate with your assigned writer and provide feedback throughout the writing process'
        },
        {
            id: 5,
            title: 'Who do I contact with burning questions?',
            body: 'If you have any questions or concerns about your order or our services, you can contact our customer support team. Our friendly and knowledgeable customer support representatives are available 24/7 to provide you with the assistance you need.'
        },
        {
            id: 6,
            title: 'Who do I contact in case of an urgent task?',
            body: 'If you have an urgent assignment that needs to be completed quickly, you can contact our customer support team to request a rush service. We will do our best to accommodate your needs and ensure that your assignment is completed on time. We strive to provide our customers with the best possible experience when using our academic writing services. Whether you need help with a complex assignment or have burning questions, our team is here to help. We offer a variety of services and discounts to meet your needs and provide you with the highest level of quality and value.'
        },
    ]
}

const gurantees = {
    column: [
        {
            id: 1,
            title: 'Do you guarantee me an A or B?',
            body: "We do not guarantee a specific grade for any assignment. While we strive to provide our clients with the highest quality work, the grade ultimately depends on many factors, including the client's academic performance and the grading criteria of the instructor. However, we do guarantee that our work will be of the highest quality and written according to the client's specifications."
        },
        {
            id: 2,
            title: 'Will my assignment be original and human generated paper?',
            body: 'Yes, we guarantee that all of our work is original and human-generated. We have a strict policy against plagiarism, and all of our writers are trained to create original content that is free of plagiarism. We also use plagiarism detection software to ensure that our work is original and free of any plagiarism.'
        },
        {
            id: 3,
            title: 'Will my assignment be delivered on time when it is urgent?',
            body: 'Yes, we understand the importance of meeting deadlines, especially when assignments are urgent. We strive to ensure that all of our work is delivered on time, even when deadlines are tight. We also offer rush services for urgent assignments to ensure that they are completed on time.'
        },
        {
            id: 4,
            title: 'What happens if am not happy with your work?',
            body: "If for any reason you are not satisfied with our work, we offer free revisions until you are satisfied with the final product. We also offer a money-back guarantee if we are unable to meet your expectations even after revisions. Our goal is to ensure that our clients are satisfied with our work and that we provide them with the highest level of service. As a professional academic writing service, we understand the importance of providing our clients with top-quality work that meets their needs. We strive to provide our clients with original, high-quality, and timely work. If for any reason you are not satisfied with our work, we offer free revisions and a money-back guarantee to ensure your satisfaction."
        }
    ]
}
        
const CustomToggle = ({ children, eventKey }) => {
    const { activeEventKey } = useContext( AccordionContext );
    const decoratedOnClick = useAccordionButton( eventKey );
    const isCurrentEventKey = activeEventKey === eventKey;
    return <button type="button" onClick={ decoratedOnClick } aria-expanded={ isCurrentEventKey ? true : false } className="edu-accordion-button">{ children }</button>
}

const Faq = ( { wrapperClass } ) => {
    const [selectedItem, setSelectedItem] = useState( 'products-services' );
    const [activeId1, setActiveId1] = useState( '0' );
    const [activeId2, setActiveId2] = useState( '0' );
    const [activeId3, setActiveId3] = useState( '0' );
    const [activeId4, setActiveId4] = useState( '0' );
    const [activeId5, setActiveId5] = useState( '0' );
    const [activeId6, setActiveId6] = useState( '0' );

    function toggleActive1( id ) {
        if (activeId1 === id) {
            setActiveId1(null);
        } else {
            setActiveId1(id);
        }
    }
    function toggleActive2( id ) {
        if (activeId2 === id) {
            setActiveId2(null);
        } else {
            setActiveId2(id);
        }
    }
    function toggleActive3( id ) {
        if (activeId3 === id) {
            setActiveId3(null);
        } else {
            setActiveId3(id);
        }
    }
    function toggleActive4( id ) {
        if (activeId4 === id) {
            setActiveId4(null);
        } else {
            setActiveId4(id);
        }
    }
    function toggleActive5( id ) {
        if (activeId5 === id) {
            setActiveId5(null);
        } else {
            setActiveId5(id);
        }
    }
    function toggleActive6( id ) {
        if (activeId6 === id) {
            setActiveId6(null);
        } else {
            setActiveId6(id);
        }
    }

    return (
        <>
            <SEO title="Frequently Asked Questions" />
            <Layout>
                <BreadcrumbOne 
                    title="Frequently Asked Questions"
                    rootUrl="/"
                    parentUrl="Home"
                    currentUrl="FAQ"
                />
                <div className={ `edu-accordion-area accordion-shape-1 edu-section-gap bg-color-white ${ wrapperClass ? wrapperClass : '' } ` }>
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="section-title text-center">
                                    <span className="pre-title">FaQ Questions</span>
                                    <h3 className="title">Get Every General Answers <br /> From Here</h3>
                                </div>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-lg-12">
                                <ul className="edu-tab-nav nav nav-tabs mt--40">
                                    {
                                        faqCategories.map( ( button ) => (
                                            <li className="nav-item" key={ button.id }>
                                                <button
                                                    className={ button.slug === selectedItem ? 'nav-link active' : 'nav-link'}
                                                    type="button"
                                                    aria-label={button.label}
                                                    onClick={ () => setSelectedItem( button.slug ) }
                                                >
                                                    { button.label }
                                                </button>
                                            </li>
                                        ) )
                                    }
                                </ul>
                            </div>
                        </div>
                        { selectedItem === 'products-services' && 
                            <div className="row g-5 align-items-center mt--20">
                                <div className="col-lg-12">
                                    <div className="accordion-style-1">
                                        <Accordion bsPrefix="edu-accordion" defaultActiveKey="0" flush>
                                            {
                                                productSeverices.columns.map( ( accordion, i ) => (
                                                    <Accordion.Item eventKey={i.toString()} key={i} onClick={() => toggleActive1(i.toString())} bsPrefix={`edu-accordion-item ${activeId1 === i.toString() ? 'active-item' : ''}`}>
                                                        <div className="edu-accordion-header">
                                                            <CustomToggle eventKey={i.toString()}>{accordion.title}</CustomToggle>
                                                        </div>
                                                        <Accordion.Body bsPrefix="edu-accordion-body">{accordion.body}</Accordion.Body>
                                                    </Accordion.Item>
                                                ) )
                                            }
                                        </Accordion>
                                    </div>
                                </div>
                            </div>
                        }

                        { selectedItem === 'procedures' && 
                            <div className="row g-5 align-items-center mt--20">
                                <div className="col-lg-12">
                                    <div className="accordion-style-1">
                                        <Accordion bsPrefix="edu-accordion" defaultActiveKey="0" flush>
                                            {
                                                procedures.column.map( ( accordion, i ) => (
                                                    <Accordion.Item eventKey={i.toString()} key={i} onClick={() => toggleActive3(i.toString())} bsPrefix={`edu-accordion-item ${activeId3 === i.toString() ? 'active-item' : ''}`}>
                                                        <div className="edu-accordion-header">
                                                            <CustomToggle eventKey={i.toString()}>{accordion.title}</CustomToggle>
                                                        </div>
                                                        <Accordion.Body bsPrefix="edu-accordion-body">{accordion.body}</Accordion.Body>
                                                    </Accordion.Item>
                                                ) )
                                            }
                                        </Accordion>
                                    </div>
                                </div>
                            </div>
                        }

                        { selectedItem === 'gurantees' && 
                            <div className="row g-5 align-items-center mt--20">
                                <div className="col-lg-12">
                                    <div className="accordion-style-1">
                                        <Accordion bsPrefix="edu-accordion" defaultActiveKey="0" flush>
                                            {
                                                gurantees.column.map( ( accordion, i ) => (
                                                    <Accordion.Item eventKey={i.toString()} key={i} onClick={() => toggleActive5(i.toString())} bsPrefix={`edu-accordion-item ${activeId5 === i.toString() ? 'active-item' : ''}`}>
                                                        <div className="edu-accordion-header">
                                                            <CustomToggle eventKey={i.toString()}>{accordion.title}</CustomToggle>
                                                        </div>
                                                        <Accordion.Body bsPrefix="edu-accordion-body">{accordion.body}</Accordion.Body>
                                                    </Accordion.Item>
                                                ) )
                                            }
                                        </Accordion>
                                    </div>
                                </div>
                            </div>
                        }
                    </div>
                </div>
            </Layout>
        </>
    )
}

export default Faq;