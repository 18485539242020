import React from 'react';
import ScrollAnimation from 'react-animate-on-scroll';

const items = [
    {
        title: 'Fast and Smooth Process',
        info: 'To order an assignment paper from maintutors.com, students simply need to visit the website and fill out an order form. The form asks for basic information such as the subject of the assignment, the deadline, and any specific instructions or requirements. Once the form is submitted, the website provides an estimated completion time and a price quote.',
        icon: 'icon-book-line',
        shape: 'shape-bg-1',
        link: '#'
    },
    {
        title: 'Set your requirements',
        info: "Maintutors.com provides a flexible and customizable platform for setting requirements for student work to be done by experts. This commitment to individualized support is a testament to the website's dedication to providing high-quality academic assistance that is tailored to each student's unique needs and requirements.",
        icon: 'icon-shopping-basket-2-line',
        shape: 'shape-bg-2',
        link: '#'
    },
    {
        title: 'Get a Free Price Quote',
        info: "On submitting your assignment requirements, a  free price quote service from maintutors.com would ensure you reach an agreement with the tutors available for work. A free quote  is an excellent way for students to get an estimate of the cost of their assignment and make an informed decision about whether to use the website's services. This transparent approach to pricing is a testament to the website's commitment to providing high-quality academic support that is accessible and affordable to all students.",
        icon: 'icon-trophy-line',
        shape: 'shape-bg-3',
        link: '#'
    },
    {
        title: 'Get your class or assignments done',
        info: 'Once a student agrees on the price for their assignment, the website assigns the task to a qualified and experienced tutor who specializes in the relevant subject area. The tutor works diligently to complete the assignment before the deadline, ensuring that the student has ample time to review and request revisions if necessary.',
        icon: 'icon-trophy-line',
        shape: 'shape-bg-4',
        link: '#'
    },
    {
        title: 'Enjoy excellent Grades',
        info: 'Maintutors  provides feedback and guidance to the student throughout the process, helping them to understand complex concepts and improve their understanding of the subject matter. This not only helps the student achieve better grades on the specific assignment but also contributes to their overall academic success.',
        icon: 'icon-trophy-line',
        shape: 'shape-bg-5',
        link: '#'
    }
];

const HowItWorksOne = ( { wrapperClass, itemClass } ) => {
    return (
        <div className={ wrapperClass || 'row g-5 mt--20' }>
            { items.map( ( data , i ) => (
                <ScrollAnimation 
                    animateIn="fadeIn"
                    animateOut="fadeInOut"
                    className={ itemClass || 'service-card-single col-lg-4 col-md-6 col-12' }
                    animateOnce={ true }
                    key={ i }
                >
                    <div className={`service-card service-card-7`}>
                        <div className="inner">
                            <div className="icon">
                                <a href={ data.link }>
                                    <i className={ data.icon }></i>
                                </a>
                            </div>
                            <div className="content">
                                <h6 className="title"><a href={ data.link }>{ data.title }</a></h6>
                                <p className="description">{ data.info }</p>
                            </div>
                        </div>
                        <div className="arrow-icon">
                            <img src="/images/how-it-works/arrow-down.png" alt="Arrow Images" />
                        </div>
                    </div>
                </ScrollAnimation>
            ) ) }
        </div>
    )
}

export default HowItWorksOne;