import { useEffect, useState, useRef } from "react";
import { Modal, Form, Button, Row, Col, Toast, ToastContainer } from 'react-bootstrap';
import { MdStarRate } from 'react-icons/md';
import emailjs from '@emailjs/browser';

const types = [
    'Entire Course-plagiarism free',
    'Single assignment-plagiarism free',
    'Discussion- plagiarism free',
    'Exam',
    'Test-Proctored'
]


const QuotePopup = ({ isSearchOpen, setIsSearchOpen }) => {
    const form = useRef();
    const [submitState, setSubmitState] = useState(false);
    const [submitContent, setSubmitContent] = useState("");
    const [formData, setFormData] = useState({ name: 'Hill', email: '', phone: '', course: '', budget: 10, message: '' });
    const handleSubmit = async (e) => {
        console.log(formData)
        if(formData.name == '' || formData.email == '' || formData.phone == '' || formData.course == '' || formData.budget == undefined || formData.message == ''){
            setSubmitState(true)
            setSubmitContent("Please Input All Fields Correctly!");
            return;
        }
        emailjs
            .send("service_guif2yo", "template_qhk16ks", formData, 'HZZQeRnQ9xmTdK5t2')
            .then(
                (result) => {
                    setSubmitContent("Successfully Sent!");
                    setSubmitState(true)
                    setIsSearchOpen(false)
                },
                (error) => {
                    setSubmitContent("Failed!");
                    setSubmitState(true)
                    setIsSearchOpen(false)
                }
            );
    }
    return (
        <>
            <Modal
                show={isSearchOpen}
                onHide={() => setIsSearchOpen(false)}
                keyboard={false}
                aria-labelledby="contained-modal-title-vcenter"
                centered
                style={{
                    width: '50vw',
                    left: '25vw'
                }}
            >
                <Modal.Body style={{ padding: 0 }}>
                    <Row>
                        <Col md={12} style={{ padding: 30 }}>
                            <Form ref={form} validated>
                                <Form.Group className="mb-3" controlId="name">
                                    <Form.Label>Full Name</Form.Label>
                                    <Form.Control type="text" placeholder="Hill" style={{ border: '1px solid black', height: 50 }} onChange={(e) => setFormData({ ...formData, name: e.target.value })} />
                                </Form.Group>

                                <Form.Group className="mb-3" controlId="email">
                                    <Form.Label>Email address</Form.Label>
                                    <Form.Control type="email" placeholder="example@domain.io" style={{ border: '1px solid black', height: 50 }} onChange={(e) => setFormData({ ...formData, email: e.target.value })} />
                                </Form.Group>

                                <Form.Group className="mb-3" controlId="phone">
                                    <Form.Label>Phone/WhatsApp Number</Form.Label>
                                    <Form.Control type="text" placeholder="Phone/Whatsapp Number" style={{ border: '1px solid black', height: 50 }} onChange={(e) => setFormData({ ...formData, phone: e.target.value })} />
                                </Form.Group>

                                <Form.Group className="mb-3" controlId="course">
                                    <Form.Label>Course</Form.Label>
                                    <Form.Select style={{ fontSize: '1.5rem' }} onChange={(e) => setFormData({ ...formData, course: e.target.value})}>
                                        {
                                            types.map((type, index) => <option key={index} value={type}>{type}</option>)
                                        }
                                    </Form.Select>
                                </Form.Group>

                                <Form.Group className="mb-3" controlId="budget">
                                    <Form.Label>Budget</Form.Label>
                                    <Form.Control type="number" placeholder="$10" style={{ border: '1px solid black', height: 50 }} onChange={(e) => setFormData({ ...formData, budget: e.target.value })} />
                                </Form.Group>

                                <Form.Group className="mb-3" controlId="message">
                                    <Form.Label>Instructions</Form.Label>
                                    <Form.Control size='lg' as='textarea' rows={3} placeholder="Message" style={{ border: '1px solid black' }} onChange={(e) => setFormData({ ...formData, message: e.target.value })} />
                                </Form.Group>
                                <Row>
                                    <Col xs={12} style={{ textAlign: 'center' }}>
                                        <Button variant="primary" onClick={handleSubmit} size='lg' style={{ textAlign: 'center', width: 200, height: 50 }}>
                                            Get a Quote
                                        </Button>
                                    </Col>
                                </Row>
                            </Form>
                        </Col>
                    </Row>

                </Modal.Body>
                <ToastContainer position="middle-center">
                    <Toast show={submitState} onClose={() => setSubmitState(false)}  delay={2000} autohide style={{ zIndex: 99}}>
                        <Toast.Body style={{ fontSize: 24, color: 'black'}}>
                            <div style={{ display: 'flex', justifyContent: 'start', gap: 20}}>
                                <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center'}}>
                                    <MdStarRate/>
                                </div>
                                <div>{submitContent}</div>
                            </div>
                        
                        </Toast.Body>
                    </Toast>
                </ToastContainer>
            </Modal>
            
            
        </>


    )
}

export default QuotePopup;