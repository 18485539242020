import React from 'react'
import SEO from '../../common/SEO';
import Layout from '../../common/Layout';
import BreadcrumbOne from '../../common/breadcrumb/BreadcrumbOne';

const PrivacyPolicy = () => {
    return (
        <>
            <SEO title="Privacy Policy" />
            <Layout>
                <BreadcrumbOne 
                    title="Privacy Policy"
                    rootUrl="/"
                    parentUrl="Home"
                    currentUrl="Privacy Policy"
                />
                <div className="edu-privacy-policy-area edu-privacy-policy edu-section-gap bg-color-white">
                    <div className="container">
                        <div className="row g-5">
                            <div className="content" style={{ marginTop: 60 }}>
                                
                                <h5 className="title">Maintutors.com is an online platform that connects students with professional tutors across a wide range of subjects. The platform offers personalized and flexible tutoring services, including a unique service of doing assignments for students. Maintutors.com is committed to protecting the privacy and confidentiality of its users and has put measures in place to ensure that all personal information is kept secure.</h5>
                                <p style={{ marginTop: 40, fontSize: 18 }}>Here are some of the ways that Maintutors.com protects the privacy of its users:</p>
                                
                                <ol style={{ marginTop: 40 }}>
                                    <li>Information Collection Maintutors.com collects only the necessary personal information required to provide tutoring services to students. The platform collects information such as name, email address, payment information, and academic history.</li>
                                    <li>Use of Information Maintutors.com uses personal information collected from users to provide tutoring services, process payments, and improve the user experience. The platform does not sell or share personal information with third parties unless required by law.</li>
                                    <li>Data Security Maintutors.com uses encryption and secure communication channels to protect all data transmitted through the platform. The platform employs security measures to protect against unauthorized access, alteration, disclosure, or destruction of personal information.</li>
                                    <li>Cookies Maintutors.com uses cookies to improve the user experience and track website usage. Cookies are small data files that are stored on the user's device and contain information about their website usage. Users can choose to disable cookies in their browser settings.</li>
                                    <li>Third-Party Services Maintutors.com may use third-party services to provide specific features or functions on the platform. These services may collect personal information from users, and Maintutors.com ensures that these services comply with their privacy policy.</li>
                                    <li>Children's Privacy Maintutors.com does not knowingly collect personal information from children under the age of 13. If a parent or guardian becomes aware that their child has provided personal information to Maintutors.com, they should contact the platform immediately.</li>
                                </ol>
                    
                                <h6 style={{ marginTop: 40 }}>Maintutors.com is committed to protecting the privacy and confidentiality of its users. The platform collects only the necessary personal information required to provide tutoring services, uses encryption and secure communication channels to protect data, and employs security measures to prevent unauthorized access. Maintutors.com also uses cookies and third-party services in compliance with its privacy policy. By using Maintutors.com, users can be assured that their personal information is kept secure and confidential.</h6>
                            </div>
                        </div>
                    </div>
                </div>
            </Layout>
        </>
    )
}
export default PrivacyPolicy;