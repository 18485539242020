import React, { useEffect, useState } from 'react';
import ScrollAnimation from 'react-animate-on-scroll';
import { Link } from 'react-router-dom';
import SectionTitle from '../../components/sectionTitle/SectionTitle';
import CourseTwo from '../../components/course/CourseTypeTwo';
import { SERVER_URL } from "../../utils/fetch";

const HomeOneCourses = () => {
    const [CourseData, setCourseData] = useState([]);
    useEffect(() => {
        const getCourses = async () => {
            const response = await fetch(`${SERVER_URL}/api/course`).then(res => res.json())
            if (response.success) {
                let courses = response.data.slice(0, 4);
                courses = courses.map((course, index) => {
                    let total_rating = 0;
                    const ratings = course?.rating ? course?.rating : [];
                    if (ratings.length > 0) {
                        ratings.map(rt => {
                            total_rating += rt.mark;
                        })
                        total_rating = parseInt(total_rating / ratings.length);
                    }
                    const title = course.name.length > 40 ? `${course.name.slice(0,40)} ...` : course.name;
                    const acronym = course?.university?.acronym.length > 5 ? `${course?.university?.acronym.slice(0,5)}` : course?.university?.acronym;
                    return {
                        "id": course._id,
                        "title": title,
                        "image": `course-0${index+1}.png`,
                        "instructor": "James Carlson",
                        "duration": `${course.duration} Weeks`,
                        "level": course.level.toUpperCase(),
                        "rating": total_rating ? total_rating : 0,
                        "acronym": course?.university?.acronym,                        
                        "price": course.current_price,
                        "oldPrice": course.original_price,
                        "review": ratings.length ? ratings.length : 0,
                        "details": course.description,

                    }
                }
                )
                setCourseData(courses)
            }
        }
        getCourses();
    }, [])

    return (
        <div className="edu-course-area eduvibe-home-one-course course-wrapper-1 edu-section-gap bg-color-white">
            <div className="container eduvibe-animated-shape">
                <div className="row">
                    <div className="col-lg-12">
                        <SectionTitle
                            classes="text-center"
                            slogan="Popular Courses"
                            title="Choose A Course that You Would Like To Study About"
                        />
                    </div>
                </div>

                <div className="row g-5 mt--10">
                    {
                        CourseData.slice(0, 8).map((item) => (
                            <ScrollAnimation
                                animateIn="fadeInUp"
                                animateOut="fadeInOut"
                                className="col-12 col-sm-12 col-xl-3 col-md-6"
                                animateOnce={true}
                                key={item.id}
                            >
                                <CourseTwo data={item} />
                            </ScrollAnimation>
                        ))
                    }
                </div>

                <div className="row mt--60">
                    <div className="col-lg-12">
                        <div className="load-more-btn text-center">
                            <a className="edu-btn" href="/course">View All Courses<i className="icon-arrow-right-line-right"></i></a>
                        </div>
                    </div>
                </div>

                <div className="shape-dot-wrapper shape-wrapper d-xl-block d-none">
                    <div className="shape-image shape-image-1">
                        <img src="/images/shapes/shape-09.png" alt="Shape Thumb" />
                    </div>
                    <div className="shape-image shape-image-2">
                        <img src="/images/shapes/shape-10.png" alt="Shape Thumb" />
                    </div>
                    <div className="shape-image shape-image-3">
                        <img src="/images/shapes/shape-11.png" alt="Shape Thumb" />
                    </div>
                    <div className="shape-image shape-image-4">
                        <img src="/images/shapes/shape-12.png" alt="Shape Thumb" />
                    </div>
                </div>
            </div>
        </div>

    )
}

export default HomeOneCourses;