import React from 'react'
import { useParams } from "react-router-dom";
import SEO from '../../common/SEO';
import Layout from '../../common/Layout';
import BreadcrumbOne from '../../common/breadcrumb/BreadcrumbOne';
import ServicesData from '../../data/service/ServicesData.json';
import { slugify } from '../../utils';

const Service = () => {
    const { service_name } = useParams();
    const Service = ServicesData.find(item => slugify(item.title) == service_name) || { title: "", header: {}, body: [], footer: "" };
    const { title, header, body, footer, content } = Service;
    return (
        <>
            <SEO title={title} />
            <Layout>
                <BreadcrumbOne 
                    title={title}
                    rootUrl="/"
                    parentUrl="Home"
                    currentUrl={title}
                />
                <div className="edu-privacy-policy-area edu-privacy-policy edu-section-gap bg-color-white">
                    <div className="container">
                        <div className="row g-5">
                            <div className="content" style={{ marginTop: 60 }}>
                                {
                                    header?.title?.map(item => <h6 className="title">{item}</h6>)
                                }
                                { 
                                  content && content.map((item) => <p style={{ marginTop: 40, fontSize: 18 }}>{item}</p>)  
                                }
                                <p style={{ marginTop: 40, fontSize: 18 }}>{header.description}</p>
                                
                                <ol style={{ marginTop: 40 }}>
                                    {
                                        body?.map(item=> <li>{item}</li>)
                                    }
                                </ol>
                    
                                <h6 style={{ marginTop: 40 }}>{footer}</h6>
                            </div>
                        </div>
                    </div>
                </div>
            </Layout>
        </>
    )
}
export default Service;