import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { slugify } from '../../utils';
import SEO from '../../common/SEO';
import Layout from '../../common/Layout';
import BreadcrumbOne from '../../common/breadcrumb/BreadcrumbOne';
import SearchOne from '../../components/widgets/blog/SearchOne';
import CategoryOne from '../../components/widgets/blog/CategoryOne';
import LatestPostOne from '../../components/widgets/blog/LatestPostOne';
import TagOne from '../../components/widgets/blog/TagOne';
import BannerOne from '../../components/widgets/blog/BannerOne';
import Comment from '../blog/Comment';
import { SERVER_URL } from '../../utils/fetch'

const Months = ['Jan', 'Feb', 'Mar','Apr','May','Jun','Jul','Aug','Sep','Oct','Nov','Dec']
const BlogDetailsThree = () => {
    const { title } = useParams();
    const [currentBlog, setCurrentBlog] = useState({});

    useEffect(() => {
        const getBlogs = async () => {
            const response = await fetch(`${SERVER_URL}/api/blog`).then(res => res.json())
            if (response.success) {
                let blogs = response.data;
                blogs = blogs.map((item, index) => {
                    const blogDate = new Date(item.date);
                        return {
                            "id": index,
                            "title": item.title,
                            "image": `${item.file_name ? item.file_name :  ''}`,
                            "date": `${blogDate.getDate()} ${Months[blogDate.getMonth()]}, ${blogDate.getFullYear()}`,
                            "description": item.description,
                            "content": item.content,
                            "categories": [item.category.key],
                            "tags": item.tags,
                            "details": "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum."
                        }
                    }
                )
                blogs = blogs.sort((a, b) => a.title > b.title);
                const temp_blog = blogs.find(item => slugify(item.title) == title);
                setCurrentBlog(temp_blog);
            }
        }
        getBlogs();
    }, [])


    return (
        <>
            <SEO title={ currentBlog?.title } />
            <Layout>
                <BreadcrumbOne 
                    title={ currentBlog?.title }
                    rootUrl="/"
                    parentUrl="Home"
                    currentUrl="Blog Details"
                />
                <div className="edu-blog-details-area edu-section-gap bg-color-white">
                    <div className="container">
                        <div className="row g-5">
                            <div className="col-lg-12">
                                <div className="blog-details-1 style-variation2">
                                    <div className="content-blog-top">
                                        <div className="thumbnail">
                                            <img className="radius-small w-100 mb--30"src={`${currentBlog.image ? SERVER_URL+'/public/'+currentBlog.image : process.env.PUBLIC_URL+'/images/blog/post-01/post-default.jpg'}`} alt="Blog Thumb" />
                                        </div>
                                        <ul className="blog-meta">
                                            <li><i className="icon-calendar-2-line"></i>{ currentBlog?.date }</li>
                                        </ul>
                                        <h4 className="title">{ currentBlog?.title }</h4>
                                    </div>

                                    <div className="blog-main-content" dangerouslySetInnerHTML={{ __html : currentBlog?.content }}>
                                        
                                    </div>

                                    <div className="blog-tag-and-share mt--50">
                                        { currentBlog?.tags && currentBlog?.tags.length > 0 &&
                                            <div className="blog-tag">
                                                <div className="tag-list bg-shade">
                                                    {
                                                        currentBlog?.tags.map( ( tag, i ) => {
                                                            return (
                                                                <Link key={ i } to={process.env.PUBLIC_URL + `/tag/${ slugify( tag ) }`}>
                                                                    { tag }
                                                                </Link>
                                                            )
                                                        } )
                                                    }
                                                </div>
                                            </div>
                                        }
                                        <div className="eduvibe-post-share">
                                            <span>Share: </span>
                                            <a className="linkedin" href="#"><i className="icon-linkedin"></i></a>
                                            <a className="facebook" href="#"><i className="icon-Fb"></i></a>
                                            <a className="twitter" href="#"><i className="icon-Twitter"></i></a>
                                            <a className="youtube" href="#"><i className="icon-youtube"></i></a>
                                        </div>
                                    </div>


                                </div>
                            </div>
                            
                        </div>
                    </div>
                </div>
            </Layout>
        </>
    )
}

export default BlogDetailsThree;