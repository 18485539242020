import React from 'react';
import { Link } from 'react-router-dom';
import { slugify } from '../../utils';
import InstructorData from '../../data/instructor/InstructorData.json';

const CourseTypeOne = ({ data, classes }) => {
    // const indexOfInstructor = InstructorData.findIndex( function( instructor ) {
    //     return slugify( instructor.name ) === slugify( data.instructor );
    // } );
    // const instructorThumb = InstructorData[indexOfInstructor].image;
    // const excerpt = data.excerpt.substring(0, 142) + "...";

    return (
        <div className={`edu-card card-type-3 radius-small ${ classes ? classes : '' }`} style={{ height: '100%'}}>
            <div className="inner">
                {/* <div className="thumbnail">
                    <Link to={process.env.PUBLIC_URL + `/course-details/${data.id}`}>
                        <img className="w-100" src={`${process.env.PUBLIC_URL}/images/course/course-01/${data.image}`} alt="Course Thumb" />
                    </Link>
                    <div className="wishlist-top-right">
                        <button className="wishlist-btn"><i className="icon-Heart"></i></button>
                    </div>
                    <div className="top-position status-group left-bottom">
                        <Link className="eduvibe-status status-03" to={process.env.PUBLIC_URL + `/course-category/${ slugify( data.categories.slice( 0, 1 ) ) }`}>
                            {data.categories.slice(0, 1)}
                        </Link>
                    </div>
                </div> */}
                <div className="content">
                    <h6 className="title">
                        <Link to={process.env.PUBLIC_URL + `/university/${slugify(data.title)}`}>{data.title}</Link>
                    </h6>
                    <p>{`${data.city},${data.state} ${data.country}`}</p>
                </div>
            </div>

            <div className="card-hover-action">
                <div className="hover-content">
                    <div className="content-top">
                        
                        <div className="top-wishlist-bar">
                            <button className="wishlist-btn"><i className="icon-Heart"></i></button>
                        </div>
                    </div>

                    <h6 className="title">
                        <Link to={process.env.PUBLIC_URL + `/university/${slugify(data.title)}`}>{data.title}</Link>
                    </h6>

                    <p className="description">{ data.description }</p>
                </div>
            </div>
        </div>
    )
}
export default CourseTypeOne;