import React from 'react';
import SEO from '../../common/SEO';
import Layout from '../../common/Layout';
import BreadcrumbOne from '../../common/breadcrumb/BreadcrumbOne';
import AboutFive from '../../components/about/AboutFive';
import LogoOne from '../../components/logos/LogoOne';
import HomeFiveProgress from '../../components/home-five/HomeFiveProgress';
import AboutUsThreeMission from '../../components/about-us-three/AboutUsThreeMission';
import AboutUsThreeInstructor from '../../components/about-us-three/AboutUsThreeInstructor';
import CounterUpOne from '../../components/counterup/CounterUpOne';
import TestimonialSectionTwo from '../../components/testimonial-section/TestimonialSectionTwo';

const AboutUsThree = () => {
    return (
        <>
            <SEO title="About Us" description='We’re Professionals with Diverse Professionals Experts, ENL writers, ELS writers, tutors, and Unemployed professors ready to take your Assignments Stress'/>
            <Layout>
                <BreadcrumbOne 
                    title="About Us"
                    rootUrl="/"
                    parentUrl="Home"
                    currentUrl="About Us"
                />

                <AboutFive wrapperClass="edu-section-gapTop" />
                
                <HomeFiveProgress />

                <AboutUsThreeMission />

                <AboutUsThreeInstructor />

                <CounterUpOne />

            </Layout>
        </>
    )
}

export default AboutUsThree;