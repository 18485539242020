import React from 'react'
import SEO from '../../common/SEO';
import Layout from '../../common/Layout';
import BreadcrumbOne from '../../common/breadcrumb/BreadcrumbOne';

const TermsConditions = () => {
    return (
        <>
            <SEO title="Terms And Conditions" />
            <Layout>
                <BreadcrumbOne 
                    title="Terms And Conditions"
                    rootUrl="/"
                    parentUrl="Home"
                    currentUrl="Terms And Conditions"
                />
                <div className="edu-privacy-policy-area edu-privacy-policy edu-section-gap bg-color-white">
                    <div className="container">
                        <div className="row g-5">
                            <div className="content" style={{ marginTop: 60 }}>
                                
                                <h5 className="title">Maintutors.com is an online platform that connects students with professional tutors across a wide range of subjects. The platform offers personalized and flexible tutoring services, including a unique service of taking online classes for busy students. While using Maintutors.com, it is essential to familiarize yourself with the terms and conditions to ensure that you understand your rights and responsibilities as a user of the platform.</h5>
                                <p style={{ marginTop: 40, fontSize: 18 }}>Here are the terms and conditions of Maintutors.com:</p>
                                
                                <ol style={{ marginTop: 40 }}>
                                    <li>Use of the platform By using Maintutors.com, you agree to comply with the terms and conditions of the platform. You also agree to provide accurate and truthful information when registering for the service.</li>
                                    <li>Payment Payment for tutoring services and online classes must be made through the Maintutors.com website using the secure payment system. You agree to pay the agreed-upon price for the services provided and to do so promptly.</li>
                                    <li>Cancellation and refunds Cancellations and refunds are subject to the cancellation policy of the tutor or service provider. Students should review the cancellation policy before making a payment.</li>
                                    <li>Confidentiality Maintutors.com takes confidentiality seriously and will not disclose your personal information to third parties without your consent. However, the platform reserves the right to disclose your information if required by law.</li>
                                    <li>Intellectual property All content on the Maintutors.com website, including logos, graphics, and text, is the property of Maintutors.com and may not be used without the express written permission of the platform.</li>
                                    <li>Prohibited conduct Users of Maintutors.com are prohibited from engaging in any conduct that violates the law or the rights of others. This includes but is not limited to plagiarism, cheating, and harassment.</li>
                                    <li>Limitation of liability Maintutors.com is not responsible for any loss or damage that may result from the use of the platform or the services provided by tutors or service providers. The platform is not liable for any indirect, incidental, or consequential damages.</li>
                                </ol>
                    
                                <h6 style={{ marginTop: 40 }}>Maintutors.com provides personalized and flexible tutoring services, including a unique service of taking online classes for busy students. By using Maintutors.com, you agree to comply with the terms and conditions of the platform. It is essential to review these terms and conditions to ensure that you understand your rights and responsibilities as a user of the platform.</h6>
                            </div>
                        </div>
                    </div>
                </div>
            </Layout>
        </>
    )
}
export default TermsConditions;