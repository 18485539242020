import React, { useState, useEffect } from 'react';
import { FaSpinner } from 'react-icons/fa';
import { useParams } from 'react-router-dom';
import SEO from '../../common/SEO';
import Layout from '../../common/Layout';
import BreadcrumbOne from '../../common/breadcrumb/BreadcrumbOne';
import CourseTypeThree from '../../components/course/CourseTypeThree';
import { slugify } from '../../utils';
import { SERVER_URL } from '../../utils/fetch';
import PaginationOne from '../../components/pagination/PaginationOne';

const UniveristyDetail = () => {
    const { name } = useParams();
    const [CourseData, setCourseData] = useState([]);
    const [currentPage, setCurrentPage] = useState(0);
    const [currentUniversity, setCurrentUniversity] = useState({});

    useEffect(() => {
        const getUniversity = async () => {
            const response = await fetch(`${SERVER_URL}/api/university`).then(res => res.json());
            let univeristy = [];
            if (response.success) {

                univeristy = response.data;
                univeristy = univeristy.map((item, index) => ({
                    title: item.name,
                    country: item.country,
                    state: item.state,
                    city: item.city,
                    numberOfCourses: item.courses.length,
                    icon: 'icon-Campus',
                    link: `/university/${slugify(item.name)}`,
                    courses: item.courses,
                    meta_title: item.meta_title,
                    meta_description: item.meta_description,
                    meta_keywords: item.meta_keywords.join(",")
                }))
            }
            const temp_current_university = univeristy.find(item => slugify(item.title) == name);
            const temp_courses = temp_current_university.courses.map((course, index) => {
                let total_rating = 0;
                const ratings = course?.rating ? course?.rating : [];
                if (ratings.length > 0) {
                    ratings.map(rt => {
                        total_rating += rt.mark;
                    })
                    total_rating = parseInt(total_rating / ratings.length);
                }
                const title = course.name

                const span = document.createElement('span');
                span.innerHTML = course.description;
                let short_desc = span.innerText;
                const des_words = short_desc.split(" ");
                short_desc = des_words.length > 100 ? des_words.slice(0,100).join(" ")+" ..." : short_desc;
                return {
                    "id": course._id,
                    "title": title,
                    "image": course?.file?.name ? course?.file?.name : '',
                    "instructor": "James Carlson",
                    "duration": `${course.duration} Weeks`,
                    "level": course.level.toUpperCase(),
                    "rating": total_rating ? total_rating : 0,
                    "acronym": course?.university?.acronym,
                    "price": course.current_price,
                    "oldPrice": course.original_price,
                    "review": ratings.length ? ratings.length : 0,
                    "details": short_desc,
                    meta_keywords: course.meta_keywords,
                    meta_title: course.meta_title,
                    meta_description: course.meta_description
                }
            }
            )

            setCurrentUniversity(temp_current_university);
            setCourseData(temp_courses);
        }
        getUniversity();
    }, []);

    return (
        <>
            <SEO 
                title={currentUniversity.meta_title} 
                description={currentUniversity.meta_description} 
                keywords={currentUniversity.meta_keywords}
            />
            <Layout>
                <BreadcrumbOne
                    title={currentUniversity?.title}
                    rootUrl="/"
                    parentUrl="Home"
                    currentUrl={currentUniversity?.title}
                />
                <div className="edu-elements-area edu-section-gap bg-color-white">
                    <div className="container">
                        <div className="row g-5">
                            <div className="col-lg-12">
                                <div className="row g-5">
                                    {
                                        CourseData.slice(currentPage*5, (currentPage+1)*5).map((item) => (
                                            <div className="col-12 col-sm-12 col-lg-12" key={item.id}>
                                                <CourseTypeThree key={item.id} data={item} />
                                            </div>
                                        ))
                                    }
                                </div>
                                
                            </div>
                            <div className='col-lg-12'>
                                <PaginationOne pages={Math.ceil(CourseData.length/5)} currentPage={currentPage} setCurrentPage={setCurrentPage}/>
                            </div>
                        </div>
                    </div>
                </div>
            </Layout>
        </>
    )
}

export default UniveristyDetail;