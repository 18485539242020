import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import QuotePopup from '../quote';

const AboutFive = ({ wrapperClass }) => {
    const [isQuote, setIsQuote] = useState(false);
    return (
        <>
            <div className={`home-three-about edu-about-area about-style-4 bg-color-white ${wrapperClass || 'edu-section-gapBottom'}`}>
                <div className="container eduvibe-animated-shape">
                    <div className="row g-lg-5 g-md-5 g-sm-5">
                        <div className="col-lg-12 col-xl-12">
                            <div className="inner mt_mobile--40">
                                <div className="section-title text-start">
                                    <span className="pre-title">Who We Are</span>
                                    <h3 className="title">We do all online  Assignments class for you</h3>
                                </div>
                                <div className="feature-style-6 mt--40">
                                    <div className="edu-feature-list color-variation-1">
                                        <div className="icon">
                                            <img src="/images/about/about-05/student.png" alt="Icons Images" />
                                        </div>
                                        <div className="content">
                                            <h6 className="title">Professional Experienced tutors</h6>
                                            <p className="description">The tutors at maintutors.com are carefully vetted and undergo a rigorous selection process to ensure that they are capable of providing top-notch assistance to students. They have a proven track record of success in helping students overcome academic challenges and achieve their goals.</p>
                                        </div>
                                    </div>

                                    <div className="edu-feature-list color-variation-2">
                                        <div className="icon">
                                            <img src="/images/about/about-05/book.png" alt="Icons Images" />
                                        </div>
                                        <div className="content">
                                            <h6 className="title">Dedicated to meeting deadlines</h6>
                                            <p className="description">Maintutors.com has a streamlined process in place to ensure that all work is completed within the given timeframe. When students submit their assignments, the website provides an estimated completion time, allowing students to plan accordingly. Maintutors.com also keeps students informed of progress updates, providing them with peace of mind that their work is being handled efficiently.</p>
                                        </div>
                                    </div>

                                    <div className="edu-feature-list color-variation-3">
                                        <div className="icon">
                                            <img src="/images/about/about-05/reward.png" alt="Icons Images" />
                                        </div>
                                        <div className="content">
                                            <h6 className="title">Money Back Guarantee</h6>
                                            <p className="description">We are confident in the quality of our service, and we offer a money-back guarantee to our clients. If you are not satisfied with the service that we provide, we will refund your money. This is our way of showing our commitment to providing the best tutoring service in the industry.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="read-more-btn mt--30">
                                <button className="edu-btn" onClick={() => setIsQuote(true)}>Get a free price quote <i className="icon-arrow-right-line-right"></i></button>
                            </div>
                        </div>
                    </div>

                    <div className="shape-dot-wrapper shape-wrapper d-xl-block d-none">
                        <div className="shape-image shape-image-1">
                            <img src="/images/shapes/shape-03-08.png" alt="Shape Thumb" />
                        </div>
                        <div className="shape-image shape-image-2">
                            <img src="/images/shapes/shape-27.png" alt="Shape Thumb" />
                        </div>
                    </div>
                </div>
            </div>
            <QuotePopup
                isSearchOpen={isQuote}
                setIsSearchOpen={setIsQuote}
            />
        </>

    )
}

export default AboutFive;