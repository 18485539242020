import React, { useState, useContext, useEffect, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { Accordion } from 'react-bootstrap';
import { useAccordionButton } from 'react-bootstrap/AccordionButton';
import AccordionContext from 'react-bootstrap/AccordionContext';
import { slugify } from '../../utils';
import SEO from '../../common/SEO';
import Layout from '../../common/Layout';
import BreadcrumbOne from '../../common/breadcrumb/BreadcrumbOne';
import CourseInfo from '../../components/course/CourseInfo';
import QuotePopup from "../../components/quote";
import taskTypes from '../../data/custom.json';
import { SERVER_URL } from "../../utils/fetch";

const CustomToggle = ({ children, eventKey }) => {
    const { activeEventKey } = useContext(AccordionContext);
    const decoratedOnClick = useAccordionButton(eventKey);
    const isCurrentEventKey = activeEventKey === eventKey;
    return <button type="button" onClick={decoratedOnClick} aria-expanded={isCurrentEventKey ? true : false} className="edu-accordion-button">{children}</button>
}

const CurriculumContent = ({ tasks }) => {
    const [activeId, setActiveId] = useState('0');

    function toggleActive(id) {
        if (activeId === id) {
            setActiveId(null);
        } else {
            setActiveId(id);
        }
    }

    return (
        <Accordion bsPrefix="edu-accordion-02" defaultActiveKey={activeId} flush>
            {
                tasks?.map((accordion, i) => {

                    const span = document.createElement('span');
                    span.innerHTML = accordion.description;
                    let short_desc = span.innerText;
                    const des_words = short_desc.split(" ");
                    short_desc = des_words.length > 100 ? des_words.slice(0, 100).join(" ") + " ..." : short_desc;

                    return (
                        <Accordion.Item eventKey={i.toString()} key={i} onClick={() => toggleActive(i.toString())} bsPrefix={`edu-accordion-item ${activeId === i.toString() ? 'bg-active' : ''}`}>
                            <div className="edu-accordion-header">
                                <Link to={`/task/${slugify(accordion.name)}`}>
                                    <CustomToggle eventKey={i.toString()}>{accordion.name}</CustomToggle>
                                </Link>

                            </div>
                            <Accordion.Body bsPrefix="edu-accordion-body">
                                <ul>
                                    <li>
                                        <div className="text">
                                            <div >
                                                {short_desc}
                                            </div>
                                        </div>
                                        <div className="icon"><i className="icon-lock-password-line"></i></div>
                                    </li>
                                </ul>
                            </Accordion.Body>
                        </Accordion.Item>
                    )
                })
            }
        </Accordion>
    )
}

const DocumentCard = ({ document, course }) => {

    return (
        <>
            <Link to={`/file/${document.name}`}>
                <div className="document-card-box">

                    <div className="document-typograph">{document.title}</div>
                    <div className="document-title">{course.university}</div>
                    <div className="document-typograph">{taskTypes.find(item => item.key == document.type).label}</div>
                    <div className="document-typograph">${course.price}</div>
                    <div className='document-typograph'>{course.title}</div>
                </div>
            </Link>

        </>
    )
}

const DocumentContent = ({ documents, course }) => {
    const [activeType, setActiveActiveType] = useState('all');
    const [filteredDocuments, setFilteredDocuments] = useState(documents);
    const [page, setPage] = useState( 0 );

    const documentPerpage = 20;

    const paginate = (items) => {
        const perPage = documentPerpage;
        const numberOfPages = Math.ceil(items.length / perPage);

        const newCourses = Array.from({ length: numberOfPages }, (_, index) => {
            const start = index * perPage;
            return items.slice(start, start + perPage)
        })

        return newCourses;
    }

    const data = paginate(filteredDocuments);

    const nextPage = () => {
        setPage((oldPage) => {
            let nextPage = oldPage + 1;
            if (nextPage > data.length - 1) {
                nextPage = 0;
            }
            return nextPage;
        })
    }

    const prevPage = () => {
        setPage((oldPage) => {
            let prevPage = oldPage - 1;
            if (prevPage < 0) {
                prevPage = data.length - 1;
            }
            return prevPage;
        })
    }

    const handlePage = (index) => {
        setPage(index);
    }

    const types = useMemo(() => {
        let temp_types = [{ type: 'all', numbers: documents.length, label: 'All' }];
        for (let i = 0; i < taskTypes.length; i++) {
            const taskType = taskTypes[i];
            const numbers = documents?.filter(item => item.type == taskType.key).length ? documents?.filter(item => item.type == taskType.key).length : 0;
            temp_types.push({ type: taskType.key, numbers, label: taskType.label })
        }
        temp_types = temp_types.filter(item => item.numbers > 0);
        return temp_types;
    }, [])

    useEffect(() => {
        let temp_documents = [...documents];
        if (activeType != 'all') {
            temp_documents = temp_documents.filter(item => item.type == activeType);
        }
        setFilteredDocuments(temp_documents);
    }, [activeType])

    return (
        <>
            <div className='document-title'>
                {
                    types.map(item => <span className='document-title-section' onClick={() => setActiveActiveType(item.type)}>{`${item.label} (${item.numbers})`}</span>)
                }
            </div>
            <div className='document-content'>
                <div className="row g-5">
                    {
                        data[page] !== undefined &&
                        data[page].map((item, index) => <div className="col-xl-3 col-lg-3">
                            <DocumentCard
                                document={item}
                                course={course}
                            />
                        </div>)
                    }

                </div>

                {data.length > 1 &&
                    <div className="row">
                        <div className="col-lg-12 mt--60">
                            <div className="nav">
                                <ul className="edu-pagination">
                                    <li className="prev-btn">
                                        <button onClick={prevPage}>
                                            <i className="ri-arrow-drop-left-line"></i>
                                        </button>
                                    </li>
                                    {data.map((_, index) => {
                                        return (
                                            <li
                                                key={index}
                                                className={`page-item ${index === page ? ' active' : ''}`}
                                            >
                                                <button onClick={() => handlePage(index)}>
                                                    {index + 1}
                                                </button>
                                            </li>

                                        )
                                    })}
                                    <li className="next-btn">
                                        <button onClick={nextPage}>
                                            <i className="ri-arrow-drop-right-line"></i>
                                        </button>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                }
            </div>
        </>
    )
}

const CourseDetails = () => {

    const { name } = useParams();
    const [CourseData, setCourseData] = useState([]);
    const [data, setData] = useState({});
    const courseId = name;
    const [isQuote, setIsQuote] = useState(false);
    const [contentTab, setContentTab] = useState('overview');
    const handleTab = (content) => {
        if (content === 'overview') {
            setContentTab('overview');
        } else if (content === 'task') {
            setContentTab('task');
        } else if (content === 'instructor') {
            setContentTab('instructor');
        } else if (content === 'reviews') {
            setContentTab('reviews');
        } else if (content === 'documents') {
            setContentTab('documents');
        }
    }

    useEffect(() => {
        const getCourses = async () => {
            const response = await fetch(`${SERVER_URL}/api/course/name/${name}`).then(res => res.json())
            if (response.success) {
                let course = response.data;
                let total_rating = 0;
                const ratings = course?.rating ? course?.rating : [];
                if (ratings.length > 0) {
                    ratings.map(rt => {
                        total_rating += rt.mark;
                    })
                    total_rating = parseInt(total_rating / ratings.length);
                }

                const span = document.createElement('span');
                span.innerHTML = course.description;
                let short_desc = span.innerText;
                const des_words = short_desc.split(" ");
                short_desc = des_words.length > 100 ? des_words.slice(0, 100).join(" ") + " ..." : short_desc;

                let currentCourse = {
                    "id": course._id,
                    "title": course.name,
                    "image": course?.file?.name ? course?.file?.name : '',
                    "instructor": "James Carlson",
                    "duration": `${course.duration} Weeks`,
                    "level": course.level.toUpperCase(),
                    "rating": total_rating ? total_rating : 0,
                    "acronym": course?.university?.acronym,
                    "price": course.current_price,
                    "oldPrice": course.original_price,
                    "review": ratings.length ? ratings.length : 0,
                    "details": course.description,
                    "reviews": course.rating,
                    "tasks": course.tasks,
                    "university": course.university.name,
                    "code": course.code,
                    'meta_title': course.meta_title,
                    'meta_description': course.meta_description,
                    'documents': course.documents ? course.documents : [],
                    'meta_keywords': course.meta_keywords.join(',')
                }
                setData(currentCourse ? currentCourse : {})
            }
        }
        getCourses();
    }, [])


    return (
        <>
            <SEO title={data?.meta_title} description={data.meta_description} keywords={data.meta_keywords} />
            <Layout>
                <BreadcrumbOne
                    title={data?.title}
                    rootUrl="/"
                    parentUrl="Home"
                    currentUrl={data?.title}
                />
                <div className="edu-course-details-area edu-section-gap bg-color-white">
                    <div className="container">

                        <div className="row g-5">
                            <div className="col-xl-8 col-lg-7">
                                <div className="course-details-content">
                                    <div className='order-content' style={{ display: 'flex', flexDirection: 'column', background: 'lightgrey', padding: 20 }}>
                                        <div className='title' style={{ display: 'flex', justifyContent: 'center' }}>
                                            <h6>Get Started</h6>
                                        </div>
                                        <div className='content' style={{ display: 'flex', justifyContent: 'space-between', gap: 30 }}>
                                            <button
                                                style={{ background: '#525fe1', border: 'none', padding: 20, color: 'white', borderRadius: 5 }}
                                                onClick={() => setIsQuote(true)}
                                            >
                                                Hire us to take your Entire coursework
                                            </button>
                                            <button
                                                style={{ background: '#525fe1', border: 'none', padding: 20, color: 'white', borderRadius: 5 }}
                                                onClick={() => setIsQuote(true)}
                                            >
                                                Order Single Assignment
                                            </button>
                                        </div>

                                    </div>
                                    <h4 className='title'>About This Course</h4>

                                    <div className="content-top">
                                        <div className="edu-rating rating-default eduvibe-course-rating-stars">
                                            <div className="rating eduvibe-course-rating-stars">
                                                {
                                                    Array(5).fill("").map((_, index) => {
                                                        if (index + 1 <= data.rating) {
                                                            return <i className="icon-Star" />
                                                        } else {
                                                            return <i key={`${index}-23`} className="off icon-Star" />
                                                        }
                                                    })
                                                }
                                            </div>
                                            <span className="rating-count">({data.review} Review) | {data.university}</span>
                                        </div>
                                    </div>

                                    <ul className="edu-course-tab nav nav-tabs" role="tablist">
                                        <li className="nav-item">
                                            <button
                                                className={contentTab === 'overview' ? 'nav-link active' : 'nav-link'}
                                                type="button"
                                                aria-label="Overview"
                                                onClick={() => handleTab('overview')}
                                            >
                                                Overview
                                            </button>
                                        </li>
                                        <li className="nav-item">
                                            <button
                                                className={contentTab === 'task' ? 'nav-link active' : 'nav-link'}
                                                type="button"
                                                aria-label="Task"
                                                onClick={() => handleTab('task')}
                                            >
                                                Task
                                            </button>
                                        </li>
                                        <li className="nav-item">
                                            <button
                                                className={contentTab === 'reviews' ? 'nav-link active' : 'nav-link'}
                                                type="button"
                                                aria-label="Reviews"
                                                onClick={() => handleTab('reviews')}
                                            >
                                                Reviews
                                            </button>
                                        </li>
                                        <li className="nav-item">
                                            <button
                                                className={contentTab === 'documents' ? 'nav-link active' : 'nav-link'}
                                                type="button"
                                                aria-label="Documents"
                                                onClick={() => handleTab('documents')}
                                            >
                                                Documents
                                            </button>
                                        </li>
                                    </ul>

                                    <div className="tab-content">
                                        {contentTab === 'overview' &&
                                            <div className={`tab-pane fade show ${contentTab === 'overview' ? 'active' : ''} `}>
                                                <div className="course-tab-content" dangerouslySetInnerHTML={{ __html: data.details }} />
                                            </div>
                                        }

                                        {contentTab === 'task' &&
                                            <div className={`tab-pane fade show active `}>
                                                <div className="course-tab-content">
                                                    <CurriculumContent tasks={data.tasks} />
                                                </div>
                                            </div>
                                        }


                                        {contentTab === 'reviews' &&
                                            <div className={`tab-pane fade show ${contentTab === 'reviews' ? 'active' : ''} `}>
                                                <div className="course-tab-content">
                                                    <div className="row row--30">
                                                        <div className="col-lg-4">
                                                            <div className="rating-box">
                                                                <div className="rating-number">{data.rating}</div>
                                                                <div className="rating eduvibe-course-rating-stars">
                                                                    {
                                                                        Array(5).fill("").map((_, index) => {
                                                                            if (index + 1 <= data.rating) {
                                                                                return <i className="icon-Star" />
                                                                            } else {
                                                                                return <i key={`${index}-23`} className="off icon-Star" />
                                                                            }
                                                                        })
                                                                    }
                                                                </div>
                                                                <span>({data.review} Review)</span>
                                                            </div>
                                                        </div>

                                                    </div>
                                                    <div className="comment-wrapper pt--40">
                                                        <div className="section-title">
                                                            <h5 className="mb--25">Reviews</h5>
                                                        </div>
                                                        {
                                                            data.reviews?.map(review => <div className="edu-comment">

                                                                <div className="comment-content">
                                                                    <div className="comment-top">
                                                                        <h6 className="title">{review?.name}</h6>
                                                                        <div className="rating eduvibe-course-rating-stars">
                                                                            {
                                                                                Array(5).fill("").map((_, index) => {
                                                                                    if (index + 1 <= review.mark) {
                                                                                        return <i className="icon-Star" />
                                                                                    } else {
                                                                                        return <i key={`${index}-23`} className="off icon-Star" />
                                                                                    }
                                                                                })
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                    <p>{review?.review}</p>
                                                                </div>
                                                            </div>)
                                                        }

                                                    </div>
                                                </div>
                                            </div>
                                        }
                                        {contentTab === 'documents' &&
                                            <div className={`tab-pane fade show active `}>
                                                <div className="course-tab-content">
                                                    <DocumentContent
                                                        documents={data?.documents ? data?.documents : []}
                                                        course={data}
                                                    />
                                                </div>
                                            </div>
                                        }
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-4 col-lg-5">
                                <CourseInfo data={data} />
                            </div>
                        </div>
                        {/* <div className="row">
                            <div className="col-lg-12">
                                <RelatedCourses courseID={data.id} />
                            </div>
                        </div> */}
                    </div>
                </div>
                <QuotePopup
                    isSearchOpen={isQuote}
                    setIsSearchOpen={setIsQuote}
                />
            </Layout>
        </>
    )
}
export default CourseDetails;