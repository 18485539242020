import React from 'react'
import SEO from '../../common/SEO';
import Layout from '../../common/Layout';
import BreadcrumbOne from '../../common/breadcrumb/BreadcrumbOne';

const HowItWorks = () => {
    return (
        <>
            <SEO title="How It Works" />
            <Layout>
                <BreadcrumbOne 
                    title="How It Works"
                    rootUrl="/"
                    parentUrl="Home"
                    currentUrl="How It Works"
                />
                <div className="edu-privacy-policy-area edu-privacy-policy edu-section-gap bg-color-white">
                    <div className="container">
                        <div className="row g-5">
                            <div className="content" style={{ marginTop: 60 }}>
                                
                                <h5 className="title">Maintutors.com is an online platform that connects students with professional tutors across a wide range of subjects. The platform offers personalized and flexible tutoring services that allow students to learn at their own pace and from the comfort of their own homes. One of the unique features of Maintutors.com is its ability to offer custom services that meet the specific needs of students.</h5>
                                <p style={{ marginTop: 40, fontSize: 18 }}>So, how does it work? Let’s take a closer look at the process:</p>
                                
                                <ol style={{ marginTop: 40 }}>
                                    <li>Fill up the price quote form The first step is to fill out the price quote form on the Maintutors.com website. This form allows students to provide details about the tutoring services they need, including the subject, level of difficulty, and the deadline for completion. Students can also upload any relevant documents or files.</li>
                                    <li>Make an agreement on the budget Once the form is submitted, a tutor will review the request and make a proposal. The tutor will suggest a budget for the project based on the requirements specified in the price quote form. Students can then negotiate the budget and make an agreement on the price.</li>
                                    <li>Make payment After the budget is agreed upon, students can make payment using the secure payment system on the Maintutors.com website. The platform accepts various payment methods, including credit cards, PayPal, and other online payment options.</li>
                                    <li>Download your job Once payment is made, the tutor will begin working on the project. Students can track the progress of the project and communicate with the tutor through the virtual classroom on the Maintutors.com website. When the project is completed, students can download the completed job from the website.</li>
                                    <li>Enjoy your grades The final step is to enjoy the good grades. With the custom tutoring services offered by Maintutors.com, students can rest assured that they will receive high-quality work that meets their specific needs. This will enable them to achieve their academic goals and improve their overall performance.</li>
                                </ol>
                    
                                <h6 style={{ marginTop: 40 }}>Maintutors.com provides a convenient and effective way for students to access personalized and flexible tutoring services. With a simple price quote form, the ability to negotiate a budget, a secure payment system, and a virtual classroom, Maintutors.com is an excellent choice for students looking to improve their academic performance.</h6>
                            </div>
                        </div>
                    </div>
                </div>
            </Layout>
        </>
    )
}
export default HowItWorks;