import React, { useState } from 'react';
import FsLightbox from 'fslightbox-react';
import QuotePopup from "../quote";

const CourseInfo = ( { data }) => {
    const [toggler, setToggler] = useState( false );
    const [quote, setQuote] = useState(false);
    return (
        <div className="eduvibe-sidebar course-details-sidebar">
            <div className="inner">
                <div className="eduvibe-widget">
                    <div className="video-area">
                        <div className="thumbnail video-popup-wrapper">
                            <img className="radius-small w-100" src={`${process.env.PUBLIC_URL}/images/course/video-bg/course-01.jpg`} alt="Course Video Thumb" />
                            <button onClick={ () => setToggler( ! toggler ) } className="video-play-btn position-to-top video-popup-activation">
                                <span className="play-icon"></span>
                            </button>
                            <FsLightbox toggler={ toggler } sources={ data.videoLink } />
                        </div>
                    </div>
                    <div className="eduvibe-widget-details mt--35">
                        <div className="widget-content">
                            <ul>
                                <li><span><i className="icon-price-tag-3-line"></i> Price</span><span style={{ textAlign: 'right' }}>${data.price}</span></li>
                                <li><span><i className="icon-Pencil"></i> Code</span><span style={{ textAlign: 'right' }}>{data.code}</span></li>
                                <li><span style={{ display: 'flex' }}><i className="icon-Note-text-line"></i> Name</span><span style={{ textAlign: 'right' }}>{data.title}</span></li>
                                <li><span><i className="icon-Campus"></i> Institution</span><span style={{ textAlign: 'right' }}>{data.university}</span></li>
                                { data.type && <li><span><i className="icon-Campus"></i> Type</span><span style={{ textAlign: 'right' }}>{data.type?.toUpperCase()}</span></li>}
                                <li><span><i className="icon-time-line"></i> Duration</span><span style={{ textAlign: 'right' }}>{data.duration}</span></li>
                                <li><span><i className="icon-bar-chart-2-line"></i> Skill Level</span><span style={{ textAlign: 'right' }}>{data.level}</span></li>
                                </ul>
                            <div className="read-more-btn mt--15">
                                <button onClick={() => setQuote(true)} className="edu-btn w-100 text-center">Get A free Price Quote</button>
                            </div>
                            <div className="read-more-btn mt--30 text-center">
                                <div className="eduvibe-post-share">
                                    <span>Share: </span>
                                    <a className="linkedin" href="#"><i className="icon-linkedin"></i></a>
                                    <a className="facebook" href="#"><i className="icon-Fb"></i></a>
                                    <a className="twitter" href="#"><i className="icon-Twitter"></i></a>
                                    <a className="youtube" href="#"><i className="icon-youtube"></i></a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <QuotePopup
                isSearchOpen={quote}
                setIsSearchOpen={setQuote}
            />
        </div>
    )
}

export default CourseInfo;