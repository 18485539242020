import React, { useState } from 'react';
import { Link as ScrollTo } from 'react-scroll';
import QuotePopup from '../quote';
import { MdStarRate } from 'react-icons/md';
import { Modal, Form, Button, Row, Col, Toast, ToastContainer } from 'react-bootstrap';
import { send } from '@emailjs/browser';

const BannerOne = () => {
    const [quotePop, setQuotePop] = useState(false);
    const [formData, setFormData] = useState({ name: 'Hill', email: '', phone: '', course: '', budget: 10, message: '' });
    const [submitState, setSubmitState] = useState(false);
    const [submitContent, setSubmitContent] = useState("");
    const handleSubmit = async () => {
        console.log(formData);
        if(formData.name == '' || formData.email == '' || formData.phone == '' || formData.course == '' || formData.budget == undefined || formData.message == ''){
            setSubmitState(true)
            setSubmitContent("Please Input All Fields Correctly!");
            return;
        }
        send("service_guif2yo","template_qhk16ks",formData, 'HZZQeRnQ9xmTdK5t2')
            .then(
                (result) => {
                    setSubmitContent("Successfully Sent!");
                    setSubmitState(true)
                },
                (error) => {
                    setSubmitContent("Failed!");
                    setSubmitState(true)
                }
            );
    }
    return (
        <div className="slider-area banner-style-1 bg-image height-940 d-flex align-items-center">
            <div className="container eduvibe-animated-shape">
                <div className="row g-5 row--20">
                    <div className="order-2 order-xl-1 col-lg-12 col-xl-7">
                        <div className="banner-left-content">
                            <div className="inner">
                                <div className="content">
                                    <span className="pre-title">IMPROVE YOUR GRADES BY OUR PROFESSIONS</span>
                                    <h1 className="title">We offer Entire Coursework Solution both Existing and Original Solutions</h1>
                                    <p className="description">
                                    We deliver High-Quality Solutions for your Course, Aced Papers, A+ Research Paper Assignments, Weekly Discussion Posts, Course Final Projects and Exams. We have available study materials in all fields of coursework raging from College, Undergraduate, Post-Graduate, Masters and PhDs.
                                    </p>
                                    <div className="read-more-btn">
                                        <button onClick={() => setQuotePop(true)} className="edu-btn">Get Started Today <i className="icon-arrow-right-line-right"></i></button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='order-1 order-xl-1 col-lg-12 col-xl-5'>
                        <div className='banner-right-content free-price-quote-form'>
                            <div className='row header'>
                                <div className='col-md-12 col-sm-12'>
                                    <h3>Get Assignment Instantly</h3>
                                </div>
                            </div>
                            <div className='row body'>
                                <div className='col-md-12 col-lg-12'>
                                    <div className='row input-row'>
                                        <div className='col-md-3 col-sm-6 label'>
                                            <p>Full Name</p>
                                        </div>
                                        <div className='col-md-9 col-sm-6 content'>
                                            <input placeholder='Full Name' value={formData.name} onChange={(e) => setFormData({...formData, name: e.target.value})}/>
                                        </div>
                                    </div>
                                    <div className='row input-row'>
                                        <div className='col-md-3 col-sm-6 label'>
                                            <p>Email</p>
                                        </div>
                                        <div className='col-md-9 col-sm-6 content'>
                                            <input placeholder='Your Email' value={formData.email} onChange={(e) => setFormData({...formData, email: e.target.value})}/>
                                        </div>
                                    </div>
                                    <div className='row input-row'>
                                        <div className='col-md-3 col-sm-6 label'>
                                            <p>Phone</p>
                                        </div>
                                        <div className='col-md-9 col-sm-6 content'>
                                            <input placeholder='Phone Number' value={formData.phone} onChange={(e) => setFormData({ ...formData, phone: e.target.value})}/>
                                        </div>
                                    </div>
                                    <div className='row input-row'>
                                        <div className='col-md-3 col-sm-6 label'>
                                            <p>Course</p>
                                        </div>
                                        <div className='col-md-9 col-sm-6 content'>
                                            <input placeholder='Enter Course' value={formData.course} onChange={(e) => setFormData({...formData, course: e.target.value})}/>
                                        </div>
                                    </div>
                                    <div className='row input-row'>
                                        <div className='col-md-3 col-sm-6 label'>
                                            <p>Budget</p>
                                        </div>
                                        <div className='col-md-9 col-sm-6 content'>
                                            <input placeholder='$10' value={formData.budget} onChange={(e) => setFormData({...formData, budget: e.target.value})}/>
                                        </div>
                                    </div>
                                    <div className='row input-row'>
                                        <div className='col-md-3 col-sm-6 label'>
                                            <p>Instructions</p>
                                        </div>
                                        <div className='col-md-9 col-sm-6 content'>
                                            <input placeholder='Message' value={formData.message} onChange={(e) => setFormData({...formData, message: e.target.value})}/>
                                        </div>
                                    </div>
                                    <div className='row input-row'>
                                        <button className='edu-btn' onClick={handleSubmit}>GET FREE PRICE QUOTE</button>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>

                <div className="shape-dot-wrapper shape-wrapper d-xl-block d-none">
                    <div className="shape shape-1">
                        <img src="/images/shapes/shape-01.png" alt="Shape Thumb" />
                    </div>
                    <div className="shape shape-2">
                        <img src="/images/shapes/shape-02.png" alt="Shape Thumb" />
                    </div>
                    <div className="shape shape-3">
                        <img src="/images/shapes/shape-03.png" alt="Shape Thumb" />
                    </div>
                    <div className="shape shape-4">
                        <img src="/images/shapes/shape-04.png" alt="Shape Thumb" />
                    </div>
                    <div className="shape shape-5">
                        <img src="/images/shapes/shape-05.png" alt="Shape Thumb" />
                    </div>
                    <div className="shape shape-6">
                        <img src="/images/shapes/shape-05-05.png" alt="Shape Thumb" />
                    </div>
                </div>

                <div className="shape-round">
                    <img src="/images/banner/banner-01/shape-27.png" alt="Shape Images" />
                </div>

            </div>

            <div className="scroll-down-btn">
                <ScrollTo
                    className="round-btn"
                    to="about-us"
                    spy={true}
                    smooth={true}
                    duration={200}
                >
                    <i className="icon-arrow-down-s-line"></i>
                </ScrollTo>
            </div>
            <QuotePopup
                isSearchOpen={quotePop}
                setIsSearchOpen={setQuotePop}
            />
            <ToastContainer position="middle-center">
                <Toast show={submitState} onClose={() => setSubmitState(false)}  delay={2000} autohide style={{ zIndex: 99}}>
                    <Toast.Body style={{ fontSize: 24, color: 'black'}}>
                        <div style={{ display: 'flex', justifyContent: 'start', gap: 20}}>
                            <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center'}}>
                                <MdStarRate/>
                            </div>
                            <div>{submitContent}</div>
                        </div>
                    
                    </Toast.Body>
                </Toast>
            </ToastContainer>
        </div>

    )
}
export default BannerOne;