import React from 'react'
import { useFilterContext } from '../../context/filter_context';

const SortCourse = ( { showPerPage } ) => {
    const {
        filtered_courses: courses,
        searchbycourse,
        updateSearch
    } = useFilterContext();
    let visibleItems = showPerPage;
    const totalCourses = courses.length;
    if ( totalCourses <= visibleItems ) {
        visibleItems = totalCourses;
    }

    const sortTitle = 'Showing <span>' + visibleItems + '</span> of <span>' + totalCourses +'</span> Courses';
    
    return (
        <div className="row g-5 align-items-center">
            <div className="col-lg-4 col-md-6 col-12">
                <div className="short-by">
                    <p dangerouslySetInnerHTML={{__html: sortTitle}}></p>
                </div>
            </div>
            
        </div>
    )
}

export default SortCourse;