import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import ScrollTopButton from './ScrollTopButton';
import { slugify } from '../../utils';

const FooterOne = () => {

    useEffect(() => {
        // let Tawk_API=Tawk_API||{ }, Tawk_LoadStart = new Date();
        let s1 = document.createElement("script"),s0=document.getElementsByTagName("script")[0];
        s1.async=true;
        s1.src='https://embed.tawk.to/6401baa331ebfa0fe7f06db4/1gqjc24oe';
        s1.charset='UTF-8';
        s1.setAttribute('crossorigin','*');
        s0.parentNode.insertBefore(s1,s0);
    }, [])


    return (
        <>
            <footer className="eduvibe-footer-one edu-footer footer-style-default">
                <div className="footer-top">
                    <div className="container eduvibe-animated-shape">
                        <div className="row g-5">
                            
                            <div className="col-lg-3 col-md-6 col-sm-6 col-12">
                                <div className="edu-footer-widget explore-widget">
                                    <h5 className="widget-title">Company</h5>
                                    <div className="inner">
                                        <ul className="footer-link link-hover">
                                            <li><Link to="/"><i className="icon-Double-arrow"></i>Home</Link></li>
                                            <li><Link to="/about-us"><i className="icon-Double-arrow"></i>About Us</Link></li>
                                            <li><a href="/sitemap.xml"><i className="icon-Double-arrow"></i>Sitemaps</a></li>
                                            <li><Link to="/faq"><i className="icon-Double-arrow"></i>Faqs</Link></li>
                                            <li><Link to="/how-it-works"><i className="icon-Double-arrow"></i>How it works</Link></li>
                                            <li><Link to="/testimonial"><i className="icon-Double-arrow"></i>Testimonial</Link></li>
                                            <li><Link to="/blog"><i className="icon-Double-arrow"></i>Blog</Link></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>

                            <div className="col-lg-3 col-md-6 col-sm-6 col-12">
                                <div className="edu-footer-widget quick-link-widget">
                                    <h5 className="widget-title">Legal</h5>
                                    <div className="inner">
                                        <ul className="footer-link link-hover">
                                            <li><Link to="/terms-conditions"><i className="icon-Double-arrow"></i>Terms and Conditions</Link></li>
                                            <li><Link to="/confidentiality"><i className="icon-Double-arrow"></i>Confidentiality</Link></li>
                                            <li><Link to="/privacy-policy"><i className="icon-Double-arrow"></i>Privacy policy</Link></li>
                                            <li><Link to="/how-it-works"><i className="icon-Double-arrow"></i>How it works</Link></li>
                                            <li><Link to="/testimonial"><i className="icon-Double-arrow"></i>Testimonial</Link></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>

                            <div className='col-lg-3 col-md-6 col-sm-6 col-12'>
                                <div className="edu-footer-widget quick-link-widget">
                                    <h5 className="widget-title">Services</h5>
                                    <div className="inner">
                                        <ul className="footer-link link-hover">
                                            <li><Link to={`/services/${slugify('Assignment writing service')}`}><i className="icon-Double-arrow"></i>Assignment writing service</Link></li>
                                            <li><Link to={`/services/${slugify('Pre-existing solution')}`}><i className="icon-Double-arrow"></i>Pre-existing solution</Link></li>
                                            <li><Link to={`/services/${slugify('Entire Online class Help')}`}><i className="icon-Double-arrow"></i>Entire Online class Help</Link></li>
                                            <li><Link to={`/services/${slugify('Exam and Quiz Help')}`}><i className="icon-Double-arrow"></i>Exam and Quiz Help</Link></li>
                                            <li><Link to={`/services/${slugify('General writing service help')}`}><i className="icon-Double-arrow"></i>General writing service help</Link></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>

                            <div className="col-lg-3 col-md-6 col-sm-6 col-12">
                                <div className="edu-footer-widget">
                                    <h5 className="widget-title">Contact Info</h5>
                                    <div className="inner">
                                        <div className="widget-information">
                                            <ul className="information-list">
                                                <li><i className="icon-map-pin-line"></i>275 Quadra Street Victoria Road, New York
                                                </li>
                                                <li><i className="icon-phone-fill"></i><a href="tel: + 1 (237) 382-2839">+ 1 (765) 470-9090</a></li>
                                                <li><i className="icon-mail-line-2"></i><a target="_blank" href="mailto:yourmailaddress@example.com">maintutors24@gmail.com</a></li>
                                                <li><i className="icon-mail-line-2"></i><a target="_blank" href="mailto:yourmailaddress@example.com">Tutorasap6@gmail.com</a></li>
                                                <li><i className="icon-mail-line-2"></i><a target="_blank" href="mailto:yourmailaddress@example.com">admin@maintutors.com</a></li>

                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                                <div className="edu-footer-widget">
                                    <div className="logo">
                                        <Link to={process.env.PUBLIC_URL + "/"}>
                                            <img className="logo-light" src="/images/logo/logo-white.png" alt="Footer Logo" />
                                        </Link>
                                    </div>
                                    <p className="description">
                                    Maintutors.com is an online platform that provides assistance to students with challenging assignments and tasks. The website connects students with experienced tutors who are experts in various academic fields and can help them understand difficult concepts, complete assignments, and prepare for exams. Maintutors.com is committed to providing high-quality educational support to students and helping them achieve academic success.
                                    </p>
                                    <ul className="social-share">
                                        <li><a href="#"><i className="icon-Fb"></i></a></li>
                                        <li><a href="#"><i className="icon-linkedin"></i></a></li>
                                        <li><a href="#"><i className="icon-Pinterest"></i></a></li>
                                        <li><a href="#"><i className="icon-Twitter"></i></a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>

                        <div className="shape-dot-wrapper shape-wrapper d-md-block d-none">
                            <div className="shape-image shape-image-1">
                                <img src="/images/shapes/shape-21-01.png" alt="Shape Thumb" />
                            </div>
                            <div className="shape-image shape-image-2">
                                <img src="/images/shapes/shape-35.png" alt="Shape Thumb" />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="copyright-area copyright-default">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="inner text-center">
                                    <p>Copyright 2022 <a href="#">EduVibe</a> Designed By <a href="https://themeforest.net/user/devsvibe">DevsVibe</a>. All Rights
                                        Reserved</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
            <ScrollTopButton />
        </>
    )
}

export default FooterOne;