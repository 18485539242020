import React, { useState, useEffect } from 'react';
import Slider from 'react-slick';
import SEO from '../../common/SEO';
import Layout from '../../common/Layout';
import BreadcrumbOne from '../../common/breadcrumb/BreadcrumbOne';
import PostOne from '../../components/post/PostOne';
import { BlogCarouselParams } from '../../utils/script';
import { SERVER_URL } from '../../utils/fetch';

const Months = ['Jan', 'Feb', 'Mar','Apr','May','Jun','Jul','Aug','Sep','Oct','Nov','Dec']
const BlogCarousel = () => {
    const [items, setItems] = useState([]);

    useEffect(() => {
        const getBlogs = async () => {
            const response = await fetch(`${SERVER_URL}/api/blog`).then(res => res.json())
            if (response.success) {
                let blogs = response.data;
                blogs = blogs.map((item, index) => {
                    const blogDate = new Date(item.date);
                        return {
                            "id": index,
                            "title": item.title,
                            "image": `${item.file_name ? item.file_name :  ''}`,
                            "date": `${blogDate.getDate()} ${Months[blogDate.getMonth()]}, ${blogDate.getFullYear()}`,
                            "description": item.description,
                            "content": item.content,
                            "categories": [item.category.key],
                            "tags": item.tags,
                            "details": "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum."
                        }
                    }
                )
                blogs = blogs.sort((a, b) => a.title > b.title);
                setItems(blogs)
            }
        }
        getBlogs();
    }, [])

    return (
        <>
            <SEO title="Blog Carousel" />
            <Layout>
                <BreadcrumbOne 
                    title="Blog Carousel"
                    rootUrl="/"
                    parentUrl="Home"
                    currentUrl="Blog Carousel"
                />
                <div className="edu-elements-area edu-section-gap bg-color-white">
                    <div className="container">
                        <div className="row g-5">
                            <div className="col-lg-12">
                                <div className="section-title text-center">
                                    <span className="pre-title">Latest From News</span>
                                    <h3 className="title">Get Our Every News & Blog</h3>
                                </div>
                                
                                <Slider 
                                    className="eduvibe-post-one-carousel-wrapper mt--40 mb--50 edu-slick-button"
                                    { ...BlogCarouselParams }
                                >
                                    { 
                                        items.map((item) => (
                                            <div className="eduvibe-post-one-carousel-item" key={ item.id }>
                                                <PostOne data={item} />
                                            </div>
                                        ) )
                                    }
                                </Slider>
                            </div>
                        </div>
                    </div>
                </div>
            </Layout>
        </>
    )
}

export default BlogCarousel;