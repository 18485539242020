import React, { useContext, useEffect, useReducer } from 'react';
import reducer from '../reducers/courses_reducer';
import { SERVER_URL } from "../utils/fetch";

const defaultState = {
    courses_loading: false,
    courses_error: false,
    courses: [],
    featured_courses: []
}

const CoursesContext = React.createContext();

export const CoursesProvider = ( { children } ) => {
    const [state, dispatch] = useReducer( reducer, defaultState );

    const fetchCourses = async () => {
        dispatch( { type: 'GET_COURSES_BEGIN' } );
        try {
            const response = await fetch(`${SERVER_URL}/api/course`).then(res => res.json());
            let courses = [];
            if (response.success) {
                courses = response.data.map((course, index) => {
                    let total_rating = 0;
                    const ratings = course?.rating ? course?.rating : [];
                    if (ratings.length > 0) {
                        ratings.map(rt => {
                            total_rating += rt.mark;
                        })
                        total_rating = parseInt(total_rating / ratings.length);
                    }
                    const title = course.name;
                    const acronym = course?.university?.acronym.length > 5 ? `${course?.university?.acronym.slice(0,5)}` : course?.university?.acronym;
                    const span = document.createElement('span');
                    span.innerHTML = course.description;
                    let short_desc = span.innerText;
                    const des_words = short_desc.split(" ");
                    short_desc = des_words.length > 100 ? des_words.slice(0,100).join(" ")+" ..." : short_desc;
                    return {
                        "id": course._id,
                        "title": title,
                        "image": course?.file?.name ? course?.file?.name : '',
                        "university": course.university.name,
                        "instructor": "James Carlson",
                        "duration": `${course.duration} Weeks`,
                        "level": course.level.toUpperCase(),
                        "rating": total_rating ? total_rating : 0,
                        "acronym": course?.university?.acronym,                        
                        "price": course.current_price,
                        "oldPrice": course.original_price,
                        "review": ratings.length ? ratings.length : 0,
                        "details": short_desc,
                        "meta_keywords": course.meta_keywords

                    }
                }
                )
            }
            console.log(courses, ">>>>> 52")
            dispatch( { type: 'GET_COURSES_SUCCESS', payload: courses } );
        } catch ( error ) {
            dispatch( { type: 'GET_COURSES_ERROR' } );
        }
    }

    useEffect( () => {
        fetchCourses()
    }, [] )

    return (
        <CoursesContext.Provider value={ { ...state, fetchCourses } }>
            { children }
        </CoursesContext.Provider>
    )
}

export const useCoursesContext = () => {
    return useContext( CoursesContext )
}