import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import ScrollToTop from './components/scrolltotop/ScrollToTop';
import AboutUsThree from './pages/innerpages/AboutUsThree';
import ContactUs from './pages/innerpages/ContactUs';

import Faq from './pages/innerpages/Faq';

import HomeOne from './pages/homepages/HomeOne';
import Pricing from './pages/innerpages/Pricing';
import University from './pages/university/University';
import UniversityDetail from './pages/university/UniversityDetail';
import Course from './pages/course/Course';
import CourseDetails from './pages/detailspages/CourseDetails';
import DownloadDetail from "./pages/download";

import TaskDetail from "./pages/task/detail";
import Error from './pages/innerpages/Error';

import PrivacyPolicy from "./pages/innerpages/PrivacyPolicy";
import TermsConditions from './pages/innerpages/TermsConditions';
import Confidentiality from './pages/innerpages/Confidentiality';
import HowItWorks from "./pages/innerpages/HowItWorks";
import Testimonial from './pages/innerpages/Testimonial';
import Service from './pages/services';
import BlogCarousel from "./pages/blog/BlogCarousel";
import BlogDetailsThree from "./pages/detailspages/BlogDetailsThree";

import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';

import DocumentDetail from './pages/document/DocumentDetail';

// Import Css Here 
import './assets/scss/style.scss';

function App() {
    return (
        <Router>
            <ScrollToTop>
                <Routes>
                    <Route exact path='/' element={<HomeOne/>}/>
                    <Route exact path={`${process.env.PUBLIC_URL + '/home'}`} element={<HomeOne/>}/>
                    <Route exact path={`${process.env.PUBLIC_URL + '/about-us'}`} element={<AboutUsThree/>}/>
                    <Route exact path={`${process.env.PUBLIC_URL + '/contact-us'}`} element={<ContactUs/>}/>
                    <Route exact path={`${process.env.PUBLIC_URL + '/course'}`} element={<Course/>}/>
                    <Route exact path={`${process.env.PUBLIC_URL + '/university'}`} element={<University/>}/>
                    <Route exact path={`${process.env.PUBLIC_URL + '/university/:name'}`} element={<UniversityDetail/>}/>
                    <Route exact path={`${process.env.PUBLIC_URL + '/faq'}`} element={<Faq/>}/>
                    <Route exact path={`${process.env.PUBLIC_URL + '/course/:name'}`} element={<CourseDetails/>}/>
                    <Route exact path={`${process.env.PUBLIC_URL + '/download/:name'}`} element={<DownloadDetail/>}/>
                    <Route exact path={`${process.env.PUBLIC_URL + '/pricing'}`} element={<Pricing/>}/>
                    <Route exact path={`${process.env.PUBLIC_URL + '/task/:name'}`} element={<TaskDetail/>}/>
                    <Route exact path={`${process.env.PUBLIC_URL + '/file/:name'}`} element={<DocumentDetail/>}/>

                    <Route exact path={`${process.env.PUBLIC_URL + '/privacy-policy'}`} element={<PrivacyPolicy/>}/>
                    <Route exact path={`${process.env.PUBLIC_URL + '/terms-conditions'}`} element={<TermsConditions/>}/>
                    <Route exact path={`${process.env.PUBLIC_URL + '/confidentiality'}`} element={<Confidentiality/>}/>
                    <Route exact path={`${process.env.PUBLIC_URL + '/how-it-works'}`} element={<HowItWorks/>}/>
                    <Route exact path={`${process.env.PUBLIC_URL + '/testimonial'}`} element={<Testimonial/>}/>
                    <Route exact path={`${process.env.PUBLIC_URL + '/services/:service_name'}`} element={<Service/>}/>
                    <Route exact path={`${process.env.PUBLIC_URL + '/blog'}`} element={<BlogCarousel/>}/>
                    <Route exact path={`${process.env.PUBLIC_URL + '/blog/:title'}`} element={<BlogDetailsThree/>}/>
                    <Route path='*' element={<Error/>}/>
                </Routes>
            </ScrollToTop>
        </Router>
    );
}

export default App;
