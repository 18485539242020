import React, { useState, useContext, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { slugify } from '../../utils';
import SEO from '../../common/SEO';
import Layout from '../../common/Layout';
import BreadcrumbOne from '../../common/breadcrumb/BreadcrumbOne';
import CourseInfo from '../../components/course/CourseInfo';
import { SERVER_URL } from "../../utils/fetch";
import QuotePopup from '../../components/quote';


const TaskDetail = () => {

    const { name } = useParams();
    const [taskData, setTaskData] = useState({});
    const [similar_tasks, setSimilarTask] = useState([]);
    const [style, setStyle] = useState({ position: 'fixed', top: '65%' });
    const [modalWidth, setModalWidth] = useState(0);
    const [isQuote, setIsQuote] = useState(false);

    useEffect(() => {
        const getTasks = async () => {
            const response = await fetch(`${SERVER_URL}/api/task`).then(res => res.json());
            console.log(response)
            if (response.success) {
                let temp_tasks = response.data;
                temp_tasks = temp_tasks.map(item => {
                    let total_rating = 0;
                    const ratings = item?.rating ? item?.rating : [];
                    if (ratings.length > 0) {
                        ratings.map(rt => {
                            total_rating += rt.mark;
                        })
                        total_rating = parseInt(total_rating / ratings.length);
                    }
                    return {
                        ...item,
                        id: item._id,
                        title: item.name,
                        duration: `${item.week_module.value}`,
                        level: item.course.level.toUpperCase(),
                        acronym: item.course?.university?.acronym,
                        price: item.course.current_price,
                        oldPrice: item.course.original_price,
                        review: ratings.length ? ratings.length : 0,
                        detail: item.description,
                        tasks: item.course.tasks,
                        university: item.course.university.name,
                        course: item.course.name,
                        code: item.course.code,
                        reviews: item.rating,
                        rating: total_rating ? total_rating : 0,
                        rating_count: ratings.length,
                        meta_title: item.meta_title,
                        meta_description: item.meta_description,
                        meta_keywords: item.meta_keywords?.join(",")
                    }
                })
                const temp_task = temp_tasks.find(item => slugify(item.name) == name);
                const task_course = temp_task.course._id;
                const similar_tasks = temp_tasks.filter(item => item.course._id == task_course).slice(0,4);
                setTaskData(temp_task);
                setSimilarTask(similar_tasks);
            }
        }
        const overView = document.querySelector("#overView");
        const width = overView.clientWidth;
        getTasks();

        
        setModalWidth(width)
    }, [])

    document.addEventListener('scroll', () => {

        const overView = document.querySelector('#overView');
        const rect = overView.getBoundingClientRect();
        const top = rect.top;
        const height = overView.clientHeight;

        const width = overView.clientWidth;
        if (top + height < 900) {
            // setIsBottom(true)
            setStyle({ position: 'absolute', bottom: '30px', width })
        }else{
            // setIsBottom(false)
            setStyle({ position: 'fixed', top: '65%', width })
        }


    })


    return (
        <>
            <SEO title={taskData.meta_title} description={taskData.meta_description} keywords={taskData.meta_keywords} />
            <Layout>
                <BreadcrumbOne
                    title={taskData.title}
                    rootUrl="/"
                    parentUrl="Home"
                    currentUrl={taskData.title}
                />
                <div className="edu-course-details-area edu-section-gap bg-color-white">
                    <div className="container">

                        <div className="row g-5">
                            <div className="col-xl-8 col-lg-7">
                                <div className="course-details-content">
                                    <h3 className="title">About This Task</h3>
                                    <div className="content-top">
                                        <div class="author-meta">
                                            <div class="author-thumb">
                                                <a href={`/university/${slugify(taskData.university ? taskData.university : '')}`}>
                                                    <span class="author-title" style={{ fontSize: 20 }}><i className='icon-Campus' style={{ color: '#525FE1' }} /> {taskData.university}</span>
                                                </a>
                                            </div>
                                        </div>
                                        <div class="author-meta">
                                            <div class="author-thumb">
                                                <a href={`/course/${slugify(taskData.course ? taskData.course : "")}`}>
                                                    <span class="author-title" style={{ fontSize: 20 }}><i className='icon-Pencil' style={{ color: '#525FE1' }} /> {taskData.code}</span>
                                                </a>
                                            </div>
                                        </div>
                                        <div className="edu-rating rating-default eduvibe-course-rating-stars">
                                            <div className="rating eduvibe-course-rating-stars">
                                                {
                                                    Array(5).fill("").map((_, index) => {
                                                        if (index + 1 <= taskData.rating) {
                                                            return <i className="icon-Star" />
                                                        } else {
                                                            return <i key={`${index}-23#525fe1`} className="off icon-Star" />
                                                        }
                                                    })
                                                }
                                            </div>
                                            <span className="rating-count">({taskData.rating_count} Review) </span>
                                        </div>

                                    </div>

                                    <div className="course-details-card">
                                        <div className="course-details-two-content" id='overView' style={{ position: 'relative', minHeight: 400 }}>
                                            <div className='order-content' style={{ ...style, width: modalWidth, display: 'flex', flexDirection: 'column', background: 'lightgrey', padding: 20}}>
                                                <div className='title' style={{ display: 'flex', justifyContent: 'center'}}>
                                                    <h6>Get Started</h6>
                                                </div>
                                                <div className='content' style={{ display: 'flex', justifyContent: 'space-between', gap: 30}}>
                                                    <button 
                                                        style={{ background: '#525fe1', border: 'none', padding: 20, color: 'white', borderRadius: 5}}
                                                        onClick={() => setIsQuote(true)}
                                                    >
                                                        Hire us to take your Entire coursework
                                                    </button>
                                                    <button 
                                                        style={{ background: '#525fe1', border: 'none', padding: 20, color: 'white', borderRadius: 5}}
                                                        onClick={() => setIsQuote(true)}
                                                    >
                                                        Order Single Assignment
                                                    </button>
                                                </div>

                                            </div>
                                            <div style={{ userSelect: 'none' }} dangerouslySetInnerHTML={{ __html: taskData.description }} />
                                        </div>
                                    </div>

                                </div>
                                <div className='col-xl-12 col-lg-12 col-12'>
                                    <div className='row g-5' style={{ marginRight: '30px', marginTop: '10px' }}>
                                        {
                                            similar_tasks.map((item, index) => (
                                                <div key={index} className='col-md-6 col-lg-4 col-sm-12 edu-event event-grid-1 radius-small' style={{ cursor: 'pointer'}}>
                                                    <a href={`/task/${slugify(item.title)}`}>
                                                        <div className='inner' style={{ backgroundColor: '#EEEEEE', height: '100%' }}>
                                                            <div className='content' >
                                                                <h6 className='title'>{item.title}</h6>

                                                            </div>
                                                        </div>
                                                    </a>                                                    
                                                </div>
                                            ))
                                        }

                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-4 col-lg-5">
                                <CourseInfo data={taskData} />
                            </div>
                        </div>
                    </div>
                </div>
            </Layout>
            <QuotePopup
                isSearchOpen={isQuote}
                setIsSearchOpen={(setIsQuote)}
            />
        </>
    )
}
export default TaskDetail;