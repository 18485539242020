import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import CategoryThree from '../category/CategoryThree';
import SectionTitle from '../../components/sectionTitle/SectionTitle';
import { SERVER_URL } from "../../utils/fetch";
import { slugify } from "../../utils";

const icons = [
    'icon-Schoolbag',
    'icon-student-read',
    'icon-Pencil',
    'icon-Class',
    'icon-Setting',
    'icon-Mind',
    'icon-Headphone',
    'icon-Microscope',
    'icon-Fitness'
]

const HomeOneCategory = () => {
    const [items, setItems] = useState([]);

    useEffect(() => {
        const getUniversity = async () => {
            const response = await fetch(`${SERVER_URL}/api/university`).then(res => res.json())
            if (response.success) {
                let university = response.data;
                console.log(response, "<><<< 28")
                university = university.sort((a,b) => a.name > b.name ? 1 : -1).slice(0,9)
                university = university.map((item, index) => ({
                    title: item.name,
                    country: item.country,
                    state: item.state,
                    city: item.city,
                    numberOfCourses: item.courses.length,
                    icon: icons[index],
                    link: `/university/${slugify(item.name)}`
                }))
                university = university;
                setItems(university)
            }
        }
        getUniversity();
    }, [])
    
  return (
    
    <div className="home-one-cat edu-service-area service-wrapper-1 edu-section-gap bg-image">
        <div className="container eduvibe-animated-shape">
            <div className="row">
                <div className="col-lg-12">
                    <SectionTitle
                        classes = "text-center"
                        title = "Universities"
                    />
                </div>
            </div>

            <CategoryThree items={items} />

            <div className='row' style={{ marginTop: 20}}>
                <div className='col-lg-12 align-items-center text-center'>
                    <a className="edu-btn" href="/university">View More<i className="icon-arrow-right-line-right"></i></a>
                </div>
            </div>

            <div className="shape-dot-wrapper shape-wrapper d-xl-block d-none">
                <div className="shape-image shape-image-1">
                    <img src="/images/shapes/shape-03-01.png" alt="Shape Thumb" />
                </div>
                <div className="shape-image shape-image-2">
                    <img src="/images/shapes/shape-08.png" alt="Shape Thumb" />
                </div>
                <div className="shape-image shape-image-3">
                    <img src="/images/shapes/shape-04-01.png" alt="Shape Thumb" />
                </div>
                <div className="shape-image shape-image-4">
                    <img src="/images/shapes/shape-03-02.png" alt="Shape Thumb" />
                </div>
            </div>
        </div>
    </div>
  )
}

export default HomeOneCategory;