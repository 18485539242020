import React from 'react';
import { Link } from 'react-router-dom';
import { slugify } from '../../utils';

const Nav = () => {
    return (
        <ul className="mainmenu">
            <li>
                <Link to="/">Home</Link>
            </li>

            <li className="has-droupdown">
                <Link to="#">Company</Link>
                <ul className="submenu">
                    <li><Link to="/about-us">About Us</Link></li>
                    <li><Link to="/faq">Faq</Link></li>
                </ul>
            </li>
            
            <li className="has-droupdown">
                <Link to="#">Resources</Link>
                <ul className="submenu">
                    <li><Link to="/university">University</Link></li>
                    <li><Link to="/course">Course</Link></li>
                </ul>
            </li>
            
            <li className="has-droupdown">
                <Link to="#">Services</Link>
                <ul className="submenu">
                    <li>
                        <Link to={`/services/${slugify('Assignment writing service')}`}>Assignment writing service</Link>
                    </li>
                    <li>
                        <Link to={`/services/${slugify('Pre-Existing Solution')}`}>Pre-Existing Solution</Link>
                    </li>
                    <li>
                        <Link to={`/services/${slugify('Entire Online class Help')}`}>Entire Online class Help</Link>
                    </li>
                    <li>
                        <Link to={`/services/${slugify('Exam and Quiz Help')}`}>Exam and Quiz Help</Link>
                    </li>
                    <li>
                        <Link to={`/services/${slugify('General writing service help')}`}>General writing service help</Link>
                    </li>
                </ul>
            </li>

            <li>
                <Link to="/contact-us">Contact Us</Link>
            </li>
        </ul>
    )
}
export default Nav;
