import React, { useState, useEffect } from 'react';
import SectionTitle from '../sectionTitle/SectionTitle';
import QuotePopup from '../quote';

const NewsLettterOne = () => {
    const [isQuote, setIsQuote] = useState(false);
    return (
        <>
            <div className="edu-newsletter-area newsletter-style-1 edu-section-gap bg-color-primary">
                <div className="container eduvibe-animated-shape">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="inner text-center">
                                <SectionTitle
                                    classes="text-white text-center"
                                    slogan="Get Started Now"
                                    title="Our Professional experiences experts are waiting"
                                />
                                <div className="newsletter-form newsletter-form-style-1 mt--60">
                                    <button onClick={() => setIsQuote(true)} className='edu-btn' style={{ background: 'white', color: '#525fe1' }}>Get A Quote</button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="shape-dot-wrapper shape-wrapper d-xl-block d-none">
                        <div className="shape-image shape-image-1">
                            <img src="/images/shapes/shape-03-04.png" alt="Shape Thumb" />
                        </div>
                        <div className="shape-image shape-image-2">
                            <img src="/images/shapes/shape-16.png" alt="Shape Thumb" />
                        </div>
                        <div className="shape-image shape-image-3">
                            <img src="/images/shapes/shape-13-02.png" alt="Shape Thumb" />
                        </div>
                    </div>
                </div>
            </div>
            <QuotePopup
                isSearchOpen={isQuote}
                setIsSearchOpen={setIsQuote}
            />
        </>

    )
}

export default NewsLettterOne;