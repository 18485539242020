import React from 'react'
import SEO from '../../common/SEO';
import Layout from '../../common/Layout';
import BreadcrumbOne from '../../common/breadcrumb/BreadcrumbOne';

const Confidentiality = () => {
    return (
        <>
            <SEO title="Confidentiality" />
            <Layout>
                <BreadcrumbOne 
                    title="Confidentiality"
                    rootUrl="/"
                    parentUrl="Home"
                    currentUrl="Confidentiality"
                />
                <div className="edu-privacy-policy-area edu-privacy-policy edu-section-gap bg-color-white">
                    <div className="container">
                        <div className="row g-5">
                            <div className="content" style={{ marginTop: 60 }}>
                                
                                <h5 className="title">Maintutors.com is an online platform that connects students with professional tutors across a wide range of subjects. The platform offers personalized and flexible tutoring services, including a unique service of doing assignments for students. Maintutors.com understands the importance of confidentiality when it comes to providing academic assistance to students.</h5>
                                <h5 className='title'>Confidentiality is crucial in maintaining the trust and confidence of students who seek assistance from Maintutors.com. The platform takes confidentiality seriously and has put measures in place to ensure that students' personal information and academic work are kept confidential.</h5>
                                <p style={{ marginTop: 40, fontSize: 18 }}>Here are some ways that Maintutors.com maintains confidentiality:</p>
                                
                                <ol style={{ marginTop: 40 }}>
                                    <li>Non-Disclosure Agreement (NDA) Maintutors.com requires all tutors and service providers to sign a non-disclosure agreement (NDA). This agreement prohibits them from sharing any information about the student or their work with third parties. It ensures that all information and work provided to Maintutors.com remains confidential.</li>
                                    <li>Secure Communication Channels Maintutors.com uses secure communication channels to ensure that all information exchanged between the student and the tutor remains confidential. The virtual classroom on the platform allows students and tutors to communicate through video, audio, and chat, ensuring that the information shared remains secure.</li>
                                    <li>Encrypted Data Maintutors.com uses encryption to protect all data transmitted through the platform. This ensures that all personal and academic information is protected from unauthorized access.</li>
                                    <li>Limited Access Maintutors.com limits access to student information and academic work to authorized personnel only. This includes tutors and service providers who are assigned to work on a specific project.</li>
                                    <li>Confidentiality Policy Maintutors.com has a confidentiality policy that outlines the measures taken to protect the confidentiality of student information and work. The policy is available on the platform's website and is reviewed regularly to ensure that it is up to date and effective.</li>
                                </ol>
                    
                                <h6 style={{ marginTop: 40 }}>Maintutors.com takes confidentiality seriously when it comes to providing academic assistance to students. The platform has put measures in place to ensure that students' personal information and academic work are kept confidential. With a non-disclosure agreement, secure communication channels, encrypted data, limited access, and a confidentiality policy, Maintutors.com is committed to protecting the privacy and confidentiality of its clients.</h6>
                            </div>
                        </div>
                    </div>
                </div>
            </Layout>
        </>
    )
}
export default Confidentiality;