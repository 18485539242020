import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Element } from 'react-scroll';
import ScrollAnimation from 'react-animate-on-scroll';
import SectionTitle from '../sectionTitle/SectionTitle';
import QuotePopup from '../quote';

const items = [
    {
        title: 'High-Experienced Professionals',
        info: 'At MainTutors.com, we have a team of highly experienced professionals who are committed to providing the best tutoring service. Our tutors are experts in their respective fields, and they are passionate about helping students achieve their academic goals. We take pride in our team, and we invest in their ongoing training and development to ensure that they are up to date with the latest teaching techniques and methodologies.',
        icon: 'icon-Hand---Book'
    },
    {
        title: 'No outsourcing of your tasks to third parties',
        info: 'At MainTutors.com, we do not outsource any of our tasks to third parties. All our tutors are in-house, and we have full control over the quality of service that we provide. When you choose MainTutors.com, you can be sure that you are dealing with a reputable and reliable company that has your best interests at heart.',
        icon: 'icon-Campus'
    },
    {
        title: 'Money Back Guarantee',
        info: 'We are confident in the quality of our service, and we offer a money-back guarantee to our clients. If you are not satisfied with the service that we provide, we will refund your money. This is our way of showing our commitment to providing the best tutoring service in the industry.',
        icon: 'icon-Support'
    },
    {
        title: 'You are our Priority',
        info: 'At MainTutors.com, we put our clients first. We understand that every student has unique educational needs, and we tailor our services to meet these needs. We take the time to understand your goals, and we work with you to achieve them. When you choose MainTutors.com, you can be sure that you are working with a company that cares about your success.',
        icon: 'icon-Board'
    },
    {
        title: 'Your class is in Safe Hands',
        info: 'We understand that as a parent or a student, you want to feel confident that your class is in safe hands. At MainTutors.com, we take this responsibility seriously. We have rigorous hiring and training processes to ensure that we only hire the best tutors in the industry. We also have policies and procedures in place to ensure the safety and security of our clients.\n MainTutors.com is the best tutoring service for your educational needs. We have a team of highly experienced professionals who are committed to providing the best service. We do not outsource any of our tasks to third parties, and we offer a money-back guarantee to our clients. We put our clients first, and we ensure that your class is in safe hands. Choose MainTutors.com for the best tutoring service in the industry.',
        icon: 'icon-student'
    }
];

const HomeOneAbout = () => {
    const [searchPopup, setIsSearchPopup] = useState(false);
    return (
        <Element
            name="about-us"
            className="edu-about-area about-style-1 edu-section-gap bg-color-white"
        >
            <div className="container">
                <div className="row g-5">
                    <div className="col-lg-12">
                        <div className="inner">
                            <SectionTitle
                                slogan = "Why Us"
                                title = "24/7 Support Team"
                            />
                            <ScrollAnimation 
                                animateIn="fadeInUp"
                                animateOut="fadeInOut"
                                animateOnce={ true }
                            >
                                <p className="description">As a student or a parent, you want the best tutoring service for your educational needs. At MainTutors.com, we understand this desire, and we have designed our services to meet your expectations. In this article, we will explore why MainTutors.com is the best option for you and your family.</p>
                            </ScrollAnimation>
                            { items && items.length > 0 &&
                                <div className="about-feature-list">
                                    { items.map( ( data , i ) => (
                                        <ScrollAnimation 
                                            animateIn="fadeInUp"
                                            animateOut="fadeInOut"
                                            className="our-feature"
                                            animateOnce={ true }
                                            key={i}
                                        >
                                            <div className="our-feature">
                                                <div className="icon">
                                                    <i className={ data.icon }></i>
                                                </div>
                                                <div className="feature-content">
                                                    <h6 className="feature-title">{ data.title }</h6>
                                                    <p className="feature-description">{ data.info }</p>
                                                </div>
                                            </div>
                                        </ScrollAnimation>
                                    ) ) }
                                </div>
                            }
                            <ScrollAnimation 
                                animateIn="fadeInUp"
                                animateOut="fadeInOut"
                                className="read-more-btn"
                                animateOnce={ true }
                            >
                                <button className="edu-btn" onClick={() => setIsSearchPopup(true)}>Get A Quote<i className="icon-arrow-right-line-right"></i></button>
                            </ScrollAnimation>
                            <div className="shape shape-6 about-parallax-2 d-xl-block d-none">
                                <img src="/images/shapes/shape-07.png" alt="Shape Thumb" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <QuotePopup
                isSearchOpen={searchPopup}
                setIsSearchOpen={setIsSearchPopup}
            />
        </Element>
    )
}

export default HomeOneAbout;