import React from 'react';
import { Parallax } from 'react-scroll-parallax';

const AboutUsThreeMission = () => {
    return (
        <div className="eduvibe-about-three-mission edu-mission-vision-area edu-section-gap border-bottom-1 bg-color-white">
            <div className="container eduvibe-animated-shape">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="thumbnail mb--50">
                            <div className="shape-image image-1">
                                <Parallax speed={1} y={[0, 40]} tagOuter="figure">
                                    <img src="/images/mission/mission-gallery-01.jpg" alt="Gallery Images" />
                                </Parallax>
                            </div>
                            <div className="shape-image image-2">
                                <Parallax speed={1} y={[0, -20]} tagOuter="figure">
                                    <img src="/images/mission/mission-gallery-02.jpg" alt="Gallery Images" />
                                </Parallax>
                            </div>
                            <div className="shape-image image-3">
                                <Parallax speed={1} y={[0, 30]} tagOuter="figure">
                                    <img src="/images/mission/mission-gallery-03.jpg" alt="Gallery Images" />
                                </Parallax>
                            </div>
                            <div className="shape-image image-4">
                                <Parallax speed={1} y={[0, -18]} tagOuter="figure">
                                    <img src="/images/mission/mission-gallery-04.jpg" alt="Gallery Images" />
                                </Parallax>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="shape-dot-wrapper shape-wrapper d-xl-block d-none">
                    <div className="animated-image shape-image-1">
                        <img src="/images/shapes/shape-04-01.png" alt="Shape Thumb" />
                    </div>
                    <div className="shape shape-1">
                        <span className="shape-dot"></span>
                    </div>
                    <div className="shape shape-2">
                        <span className="shape-dot"></span>
                    </div>
                </div>
            </div>

            <div className="container">
                <div className="row g-5">
                    <div className="col-lg-12">
                        <div className="our-mission">
                            <div className="section-title text-start">
                                <span className="pre-title">Our Mission</span>
                                <p className="mt--30 mb--0">
                                    At MainTutors.com our mission is to provide high-quality academic tutoring services to students around the world. We are dedicated to helping students achieve their academic goals and reach their full potential by full course guidelines and single assignments mentoring.
                                    At MainTutors.com, we believe that education is the key to unlocking a world of opportunities. We understand that every student is unique, with their own strengths and weaknesses, and we are committed to providing personalized attention to each and every student who comes to us for help.
                                    Our team of experienced and qualified tutors are passionate about education and are dedicated to helping students succeed. We offer a wide range of educational services, including one-on-one tutoring, group tutoring, test preparation, and academic coaching.
                                    We understand that the educational landscape is constantly evolving, and we are committed to staying up-to-date with the latest trends and technologies in education. We use innovative teaching methods and technology to make learning more engaging, interactive, and effective.
                                    At MainTutors.com, we believe that education should be accessible to everyone, regardless of their background or financial situation. That’s why we offer affordable rates and flexible payment plans to make our services available to as many students as possible.
                                    We also believe in giving back to our community. That’s why we partner with local schools and non-profit organizations to provide free tutoring services to underserved communities.
                                    Our mission is simple: to help students succeed. We are committed to providing high-quality educational services that empower students to achieve their academic goals and reach their full potential. Join us on this journey and let us help you unlock a world of opportunities through education.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-12">
                        <div className="our-vision">
                            <div className="section-title text-start">
                                <span className="pre-title">Our Vision</span>
                                <p className="mt--30 mb--0">
                                    Welcome to MainTutors.com, where our vision is to empower students around the world with the knowledge and skills they need to succeed in life. We believe that education is a fundamental right and that every student deserves access to high-quality educational services.
                                    Our vision is to create a world where education is accessible to all, regardless of their background or financial situation. We want to help students achieve their academic goals and reach their full potential, regardless of where they are in the world.
                                    At MainTutors.com, we believe in the power of education to transform lives. We understand that education is not just about memorizing facts and figures, but about developing critical thinking skills, creativity, and a lifelong love of learning.
                                    Our vision is to provide educational services that go beyond traditional teaching methods. We use innovative technology and teaching methods to make learning more engaging, interactive, and effective. Our tutors are passionate about education and are committed to providing personalized attention to each and every student.
                                    We believe that education should be a collaborative effort between students, tutors, parents, and schools. That’s why we work closely with schools and parents to ensure that our services are aligned with their academic goals and objectives.
                                    Our vision is to make a positive impact on the world through education. We believe that education is the key to solving many of the world’s biggest challenges, from poverty and inequality to climate change and healthcare. That’s why we are committed to empowering students with the knowledge and skills they need to make a difference in the world.
                                    Our vision is to create a world where education is accessible to all and where students have the knowledge and skills they need to succeed in life. We believe in the power of education to transform lives and make a positive impact on the world. Join us on this journey and let us help you unlock your full potential through education.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-12">
                        <div className="our-mission">
                            <div className="section-title text-start">
                                <span className="pre-title">Our Story</span>
                                <p className="mt--30 mb--0">
                                    Welcome to MainTutors.com, where we provide professional academic help to students around the world. Our story began with a simple idea: to create a platform where students could access high-quality educational services from experienced and qualified tutors.
                                    The founders of MainTutors.com were passionate about education and understood the challenges that students face when it comes to achieving their academic goals. They recognized that many students needed additional support outside of the traditional classroom setting, and that there was a need for a more personalized approach to education.
                                    With this in mind, they set out to create a platform where students could connect with experienced tutors who could provide personalized attention and support. They assembled a team of dedicated and experienced tutors who shared their passion for education and commitment to helping students succeed.
                                    As the platform grew, MainTutors.com became a trusted name in the world of online tutoring. Students from around the world came to us for help with a wide range of subjects, from math and science to English and history. Our tutors used innovative teaching methods and technology to make learning more engaging and effective, and our students achieved impressive results.
                                    Over the years, we have continued to expand our services and reach even more students around the world. We have partnered with schools and non-profit organizations to provide free tutoring services to underserved communities, and we have developed a reputation for excellence in the world of online tutoring.
                                    Today, MainTutors.com is proud to be a leader in the field of online tutoring, providing high-quality educational services to students around the world. Our story is one of passion, dedication, and a commitment to helping students succeed. We are grateful for the opportunity to make a positive impact on the lives of our students, and we look forward to continuing to provide professional academic help for many years to come.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-12">
                        <div className="our-mission">
                            <div className="section-title text-start">
                                <span className="pre-title">Our Dedicated Support Team</span>
                                <p className="mt--30 mb--0">
                                    At MainTutors.com, we pride ourselves on providing professional academic help to students around the world. But we know that providing high-quality educational services is not just about having experienced tutors – it’s also about having a dedicated support team that is always ready to help.
                                    Our dedicated support team is made up of highly trained professionals who are committed to ensuring that every student who comes to us for help receives the support they need to succeed. Whether you have a question about our services, need help scheduling a tutoring session, or have a technical issue, our support team is here to help.
                                    We understand that students have busy lives and schedules, which is why our support team is available 24/7 to answer your questions and provide assistance. You can reach us by phone, email, or live chat, and we always strive to provide prompt and friendly service.
                                    Our support team is also committed to ensuring that every student has a positive experience with MainTutors.com. We believe that education should be a collaborative effort between students, tutors, and support staff, and we work hard to create a supportive and welcoming environment for everyone who uses our services.
                                    In addition to providing support to our students, our support team also works closely with our tutors to ensure that they have everything they need to provide the best possible educational services. We provide ongoing training and support to our tutors, and we are always looking for ways to improve our services and make them even more effective.
                                    At MainTutors.com, we understand that providing professional academic help is about more than just having experienced tutors. It’s also about having a dedicated support team that is always ready to help. Our support team is here to ensure that every student who comes to us for help receives the support they need to succeed, and we are committed to providing the best possible educational services to students around the world.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-12">
                        <div className="our-mission">
                            <div className="section-title text-start">
                                <span className="pre-title">Our Professionals writers</span>
                                <p className="mt--30 mb--0">
                                    At MainTutors.com, we provide professional academic help to students around the world, and our team of professional writers is a critical part of our success. Our writers are experts in their fields and have years of experience creating high-quality academic content for students at all levels.
                                    We understand that academic writing can be a challenge for many students. That’s why we have assembled a team of writers who are not only experts in their fields but are also skilled at crafting clear, concise, and well-structured papers that meet the highest academic standards.
                                    Our writers are experienced in a wide range of subjects, including math, science, English, history, and more. They understand the unique challenges of academic writing, from conducting research and analyzing data to formatting papers and citing sources.
                                    When you work with our professional writers, you can be confident that you are getting the best possible academic help. Our writers take the time to understand your needs and requirements, and they work closely with you to ensure that your paper meets your expectations.
                                    In addition to providing high-quality academic writing services, our writers also have a deep understanding of the academic landscape. They stay up-to-date with the latest trends and developments in their fields and are always looking for ways to improve their skills and knowledge.
                                    At MainTutors.com, we are committed to providing our students with the best possible academic help, and our team of professional writers is an essential part of that commitment. Whether you need help with a research paper, essay, or any other type of academic writing, our writers are here to help you succeed.
                                    Our team of professional writers is an essential part of our commitment to providing professional academic help to students around the world. With years of experience and a deep understanding of academic writing, our writers are dedicated to helping you achieve your academic goals and succeed in your academic career. Contact us today and let us help you achieve academic success with our top-notch academic writing services.
                                </p>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>

    )
}

export default AboutUsThreeMission;